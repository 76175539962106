import { I18n, getTranslation } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React from 'react'

import { showNotification } from '../../../Services/Utils'
import { ButtonNew } from '../../ButtonNew'
import { Flex } from '../../Flex'
import Modal from '../../Modal'
import Title from '../../Title'
import { ShareBox } from './styles'

const ShareLink = ({ isOpen, onChooseNegative, url, ...props }) => {
  const SHARED_LINK = url ? window.location.host + url : window.location.href

  const handleCopyText = e => {
    e.stopPropagation()
    navigator.clipboard.writeText(SHARED_LINK)

    showNotification(getTranslation('toast.success.urlCopied'))
    onChooseNegative()
  }

  return (
    <Modal
      hideClose
      isOpen={isOpen}
      showFooter={false}
      onCallback={() => onChooseNegative()}
      {...props}
    >
      <Flex flexDirection="column" pb={3}>
        <Title mb={2}>
          <I18n text="actions.shareColleagues" />
        </Title>
        <Flex mt={2}>
          <I18n text="collections.shareCollectionText" />
        </Flex>
        <ShareBox border="1px solid gray" alignItem="center" height="56px">
          <Flex pr={3}>{SHARED_LINK}</Flex>
          <ButtonNew buttonDiv onClick={handleCopyText} type="task">
            <I18n i18nKey="actions.copy" />
          </ButtonNew>
        </ShareBox>
      </Flex>
    </Modal>
  )
}

ShareLink.defaultProps = {
  url: '',
}

ShareLink.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  url: PropTypes.string,
  onChooseNegative: PropTypes.func.isRequired,
}

export default ShareLink
