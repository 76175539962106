import PropTypes from 'prop-types'
import React from 'react'

import { Flex } from '../../Flex'
import { ViewOnlyField, ViewOnlyLabel } from './styles'

const ViewOnly = props => {
  const { input, label, viewOnlyProps } = props

  const { hideLabel, labelTag, fieldTag } = viewOnlyProps

  const LabelContainer = labelTag || ViewOnlyLabel
  const FieldContainer = fieldTag || ViewOnlyField

  return (
    <Flex {...props} flexDirection="column">
      {label && !hideLabel && input.value && (
        <LabelContainer mt={2}>{label}</LabelContainer>
      )}
      {input.value && <FieldContainer>{input.value}</FieldContainer>}
    </Flex>
  )
}
ViewOnly.defaultProps = {
  hideLabel: false,
  label: '',
  viewOnlyProps: {},
  labelTag: <></>,
  fieldTag: <></>,
}

ViewOnly.propTypes = {
  hideLabel: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  viewOnlyProps: PropTypes.object,
  labelTag: PropTypes.element,
  fieldTag: PropTypes.element,
  input: PropTypes.object.isRequired,
}

export default ViewOnly
