export const LOAD_DATA = 'questTracker/LOAD_DATA'
export const LOAD_LESSONS_DATA = 'questTracker/LOAD_LESSONS_DATA'
export const RESET_CURRENT_LESSON = 'questTracker/RESET_CURRENT_LESSON'
export const REQUEST_ADVANCE_ACTIVITY = 'questTracker/REQUEST_ADVANCE_ACTIVITY'
export const ADVANCE_ACTIVITY = 'questTracker/ADVANCE_ACTIVITY'
export const ANIMATE_ACTIVITY = 'questTracker/ANIMATE_ACTIVITY'
export const SELECT_ACTIVITY = 'questTracker/SELECT_ACTIVITY'
export const SELECT_ACTIVITY_COMPLETE = 'questTracker/SELECT_ACTIVITY_COMPLETE'
export const COMPLETE_ACTIVITY = 'questTracker/COMPLETE_ACTIVITY'
export const REQUEST_OPEN_TASK = 'questTracker/REQUEST_OPEN_TASK'
export const OPEN_TASK = 'questTracker/OPEN_TASK'
export const OPEN_TASK_COMPLETE = 'questTracker/OPEN_TASK_COMPLETE'
export const CLOSE_TASK = 'questTracker/CLOSE_TASK'
export const CLOSE_TASK_NAVIGATION = 'questTracker/CLOSE_TASK_NAVIGATION'
export const CLOSE_TASK_COMPLETE = 'questTracker/CLOSE_TASK_COMPLETE'
export const SAVE_RESPONSE = 'questTracker/SAVE_RESPONSE'
export const SELECT_TASK = 'questTracker/SELECT_TASK'
export const CLOSE_ACTIVITY = 'questTracker/CLOSE_ACTIVITY'
export const OPEN_ACTIVITY_COMPLETE = 'questTracker/OPEN_ACTIVITY_COMPLETE'
export const CLOSE_ACTIVITY_COMPLETE = 'questTracker/CLOSE_ACTIVITY_COMPLETE'
export const UPDATE_TASK_EXPERIENCES = 'questTracker/UPDATE_TASK_EXPERIENCES'
export const SHOW_ACHIEVEMENT = 'questTracker/SHOW_ACHIEVEMENT'
export const HIDE_ACHIEVEMENT = 'questTracker/CLOSE_ACHIEVEMENT'
export const SET_IS_TEACHING_IN_FRONT_OF_CLASS =
  'questTracker/SET_IS_TEACHING_IN_FRONT_OF_CLASS'

export const questTrackerData = {
  load: lessonData => ({ type: LOAD_DATA, lessonData }),
  advanceActivity: () => ({ type: ADVANCE_ACTIVITY }),
  requestAdvanceActivity: (id, isInteraction) => ({
    type: REQUEST_ADVANCE_ACTIVITY,
    id,
    isInteraction,
  }),
  selectActivity: id => ({ type: SELECT_ACTIVITY, id }),
  animateActivity: id => ({ type: ANIMATE_ACTIVITY, id }),
  completeActivity: id => ({ type: COMPLETE_ACTIVITY, id }),
  requestOpenTask: (id, open = false) => ({
    type: REQUEST_OPEN_TASK,
    id,
    open,
  }),
  selectTask: (id, open = false) => ({ type: SELECT_TASK, id, open }),
  openTask: id => ({ type: OPEN_TASK, id }),
  closeTask: id => ({ type: CLOSE_TASK, id }),
  closeTaskNavigation: () => ({ type: CLOSE_TASK_NAVIGATION }),
  saveResponse: (id, data) => ({ type: SAVE_RESPONSE, id, data }),
  closeActivity: () => ({ type: CLOSE_ACTIVITY }),
  updateTaskExperiences: data => ({ type: UPDATE_TASK_EXPERIENCES, data }),
  showAchievement: () => ({ type: SHOW_ACHIEVEMENT }),
  hideAchievement: () => ({ type: HIDE_ACHIEVEMENT }),
  setIsTeachingInFrontOfClass: isTeachingInFrontOfClass => ({
    type: SET_IS_TEACHING_IN_FRONT_OF_CLASS,
    isTeachingInFrontOfClass,
  }),
  resetCurrentLesson: () => ({ type: RESET_CURRENT_LESSON }),
}
