import { isBoolean, omit, omitBy, pick } from 'lodash'
import PT from 'prop-types'
import React from 'react'

import Container from './styles'

export const Icon = ({ glyph: Glyph, ...props }) => {
  const rest = omitBy(props, isBoolean)
  const type = typeof Glyph
  const propsContainer = omit(props, ['svgProps', 'width', 'height'])
  return (
    <Container {...propsContainer}>
      {type !== 'object' && (
        <Glyph {...pick(rest, ['svgProps', 'width', 'height'])} />
      )}
      {type === 'object' && Glyph}
    </Container>
  )
}

Icon.propTypes = {
  glyph: PT.oneOfType([PT.element, PT.func, PT.object]).isRequired,
}

export default Icon
