import { Api as apiCall, createAsyncAction } from '@lyfta/components-data'

export const LOAD_CONTENT = createAsyncAction('content/LOAD_CONTENT')
export const LOAD_CONTENTS = createAsyncAction('content/LOAD_CONTENTS')
export const UPDATE_CONTENT = createAsyncAction('content/UPDATE_CONTENT')
export const CREATE_CONTENT = createAsyncAction('content/CREATE_CONTENT')
export const FIX_CONTENT = createAsyncAction('content/FIX_CONTENT')

export const REQUEST_UPDATE_CONTENT = 'content/REQUEST_UPDATE_CONTENT'
export const REQUEST_CREATE_CONTENT = 'content/REQUEST_CREATE_CONTENT'
export const REQUEST_CREATE_ASSET_RAW = 'content/REQUEST_CREATE_ASSET_RAW'
export const REQUEST_CREATE_CONTENT_RAW = 'content/REQUEST_CREATE_CONTENT_RAW'
export const REQUEST_DELETE_CONTENT = 'content/REQUEST_DELETE_CONTENT'
export const REQUEST_DELETE_STORYWORLD = 'content/REQUEST_DELETE_STORYWORLD'
export const REQUEST_SET_STORYWORLD_XML = 'content/REQUEST_SET_STORYWORLD_XML'

export const clearCurrent = () => ({ type: LOAD_CONTENT.CLEAR })

export const loadContent = id =>
  apiCall({
    method: 'GET',
    endpoint: `/contents/${id}`,
    query: {
      include:
        'author,tags,tags.tagGroup,favorites,favorites.content,parent,parent.parent,children,parent.children,lessonPlanTemplate,lessonPlanTemplates,lessonPlanTemplates.content,assetDetails,poster,icon,trailer,contentDetail,ageRanges,subjects',
    },
    types: LOAD_CONTENT,
    payload: { id },
  })

export const loadContents = ({ filter, page, sort } = {}) => {
  const query = {
    include:
      'author,children,tags.tagGroup,parent,parent.children,favorites,favorites.content,lessonPlanTemplate,lessonPlanTemplates,lessonPlanTemplates.content,collection',
    page,
    filter,
    sort,
  }
  query.reproducible_search_url = window.location.href

  return apiCall({
    method: 'GET',
    endpoint: '/contents',
    query,
    types: LOAD_CONTENTS,
    paged: true,
    payload: { filter, sort },
  })
}

export const requestUpdateContent = (data, locale) => ({
  type: REQUEST_UPDATE_CONTENT,
  data,
  locale,
})

export const requestCreateContent = (data, locale, contentType) => ({
  type: REQUEST_CREATE_CONTENT,
  data,
  locale,
  contentType,
})

export const requestCreateContentRaw = (
  data,
  fileDetails,
  callback,
  onProgress = null,
) => ({
  type: REQUEST_CREATE_CONTENT_RAW,
  data,
  fileDetails,
  callback,
  onProgress,
})

export const requestCreateAssetRaw = (
  fileDetails,
  callback,
  onProgress = null,
) => ({
  type: REQUEST_CREATE_ASSET_RAW,
  fileDetails,
  callback,
  onProgress,
})

export const requestDeleteStoryworld = data => ({
  type: REQUEST_DELETE_STORYWORLD,
  data,
})

export const requestDeleteContent = data => ({
  type: REQUEST_DELETE_CONTENT,
  data,
})

export const requestSetStoryworldXML = (
  data,
  xml,
  labelsCsv,
  extraUpdates,
  callback,
) => ({
  type: REQUEST_SET_STORYWORLD_XML,
  data,
  xml,
  labelsCsv,
  extraUpdates,
  callback,
})

export const updateContent = (
  attributes,
  relationships,
  preventAutoRelationships = false,
) =>
  apiCall({
    method: 'PATCH',
    endpoint: `/contents/${attributes.id}`,
    customMessage: { success: { blockDefaultMessage: true } },
    preventAutoRelationships,
    query: {
      data: {
        type: 'contents',
        attributes,
        relationships,
      },
    },
    types: UPDATE_CONTENT,
  })

export const deleteContent = attributes =>
  apiCall({
    method: 'DELETE',
    endpoint: `/contents/${attributes.id}`,
    customMessage: { success: { blockDefaultMessage: true } },
    query: {
      data: {
        type: 'contents',
      },
    },
    types: UPDATE_CONTENT,
  })

export const createContent = (attributes, relationships) =>
  apiCall({
    method: 'POST',
    endpoint: `/contents`,
    query: {
      data: {
        type: attributes.type,
        attributes,
        relationships,
      },
    },
    types: CREATE_CONTENT,
  })

export const createStoryworldS3Folder = slug =>
  apiCall({
    endpoint: `/story_worlds`,
    method: 'POST',
    needsNormalization: false,
    preventAutoRelationships: true,
    customMessage: { success: { blockDefaultMessage: true } },
    query: {
      data: {
        attributes: {
          world_slug: slug,
        },
      },
    },
    types: FIX_CONTENT,
  })
