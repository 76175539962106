import { darken, opacify } from 'polished'

const palette = {
  beige: 'hsla(30, 20%, 96%, 1)', // #7b00f1
  black: 'hsla(0, 0%, 0%, 1)', // #000000
  blackOpacity: 'hsla(0, 0%, 0%, 0.5)', // #000000, opacity 0.5
  blackOpacity80: 'hsla(0, 0%, 0%, 0.8)', // #000000, opacity 0.8
  blue: 'hsla(271, 100%, 47%, 1)', // #7b00f1
  blueLotus: 'hsla(240, 89%, 67%, 1)', // #6060f6
  darkBrown: 'hsla(232, 82%, 11%, 1)', // #050b33
  darkRoyalBlue: 'hsla(227, 100%, 24%, 1)', // #001b7b
  electricLila: 'hsla(270, 62%, 61%, 1)', // #9b5dd9
  green: 'hsla(133, 39%, 43%, 1)', //	#439855
  iris: 'hsla(240, 51%, 54%, 1)', // #4e4ec6
  lighterPurple: 'hsla(240, 60%, 50%, 1)', // #3333cc
  lyftaBeige: 'hsla(45, 40%, 90%, 1)', // #f0ebdc
  lyftaTurquoiseLight: 'hsla(188, 50%, 60%, 1)', // #68bfcc
  lyftaTurquoiseOpacity: 'hsla(188, 100%, 34%, 0.8)', // #0096ad, opacity 0.8
  offWhite: 'hsla(0, 33%, 99%, 1)', // #fefdfd
  orange: 'hsla(8, 81%, 68%, 1)', // #ef7d6b
  roseRed: 'hsla(3, 80%, 60%, 1)', // #eb5047
  scarletRed: 'hsla(8, 81%, 54%, 1)', // #e8442a
  silver: 'hsla(0, 0%, 80%, 1)', // #cccccc
  tealBlue: 'hsla(183, 100%, 32%, 1)', //	#009ba3
  tealLightBlue: 'hsla(182, 65%, 82%, 1)', // #b3edef
  transparentShadow: 'hsla(0, 0%, 0%, 0.25)', // #000000, opacity: 0.25
  tundora: 'hsla(0, 0%, 29%, 1)', // #4a4a4a
  ufoGreen: 'hsla(133, 40%, 52%, 1)', // #54b569
  ufoGreen50: 'hsla(134, 51%, 79%, 1)', // #aee5bb
  ufoGreenOpacity: 'hsla(133, 40%, 52%, 0.8)', // #54b569, opacity: 0.8
  white: 'hsla(0, 0%, 100%, 1)', // #ffffff
  whiteOpacity: 'hsla(0, 0%, 100%, 0.9)', // #ffffff, opacity: 0.9
  whiteSmoke: 'hsla(0, 0%, 94%, 1)', // #f0f0f0
  pink: 'hsla(336, 96%, 65%, 1)', // #FB4F93

  // miscellaneous grays
  alto: 'hsla(20, 7%, 84%, 1)', // #dad6d4 // same as neutral_400
  boulder: 'hsla(0, 0%, 46%, 1)', // #757575
  codGray: 'hsla(0, 0%, 10%, 1)', // #1a1a1a
  concrete: 'hsla(220, 16%, 96%, 1)', // #f4f5f7
  darkDustyGrey: 'hsla(0, 0%, 26%, 1)', // #434343
  darkGray: 'hsla(0, 0%, 18%, 1)', // #2e2e2e
  doveGray: 'hsla(0, 0%, 40%, 1)', // #666666
  dustyGray: 'hsla(0, 0%, 59%, 1)', // #979797
  lightGray: 'hsla(0, 0%, 63%, 1)', // #a0a0a0
  gray: 'hsla(0, 1%, 51%, 1)', // #838181
  grayHover: 'hsla(229, 26%, 21%, 1)', // #272c43
  grayMedium: 'hsla(230, 13%, 35%, 1)', // #4d5164
  offGrey: 'hsla(20, 2%, 37%, 1)', // #615f5e
  slateGray: 'hsla(215, 10%, 37%, 1)', //	#555d68

  // DS colors
  teal_60: 'hsla(187, 39%, 87%, 1)', // #d1e8eb
  teal_70: 'hsla(189, 51%, 74%, 1)', // #99d4de
  teal_100: 'hsla(188, 100%, 34%, 1)', // #0094ac
  teal_130: 'hsla(188, 90%, 20%, 1)', // #055562

  blue_60: 'hsla(229, 63%, 78%, 1)', // #a4b1ea
  blue_80: 'hsla(231, 62%, 58%, 1)', // #5164d6
  blue_100: 'hsla(238, 63%, 49%, 1)', // #2e34ce
  blue_130: 'hsla(233, 66%, 34%, 1)', // #1e2c91

  black_200: 'hsla(218, 17%, 91%, 1)', // #e4e7ec
  black_300: 'hsla(212, 16%, 81%, 1)', // #c8cfd7
  black_400: 'hsla(213, 17%, 72%, 1)', // #ACB7C4;
  black_500: 'hsla(214, 17%, 54%, 1)', // #76879D;
  black_600: 'hsla(216, 20%, 34%, 1)', // #455368
  black_700: 'hsla(222, 33%, 20%, 1)', // #232d45
  black_800: 'hsla(232, 83%, 11%, 1)', // #050b35
  black_900: 'hsla(218, 62%, 5%, 1)', // #050b15

  neutral_100: 'hsla(0, 0%, 98%, 1)', // #fafafa
  neutral_200: 'hsla(40, 14%, 96%, 1)', // #f6f5f3
  neutral_300: 'hsla(26, 21%, 94%, 1)', // #f2eeeb
  neutral_400: 'hsla(20, 7%, 84%, 1)', // #dad6d4

  green_60: 'hsla(151, 62%, 90%, 1)', // #d4f5e5
  green_80: 'hsla(152, 64%, 69%, 1)', // #7ce2b2
  green_100: 'hsla(147, 62%, 52%, 1)', // #37d07b
  green_130: 'hsla(146, 56%, 27%, 1)', // #1e6a3f

  red_60: 'hsla(5, 73%, 82%, 1)', // #f3b7b1
  red_90: 'hsla(6, 75%, 64%, 1)', // #e86c5d
  red_100: 'hsla(8, 76%, 53%, 1)', // #e3462d
  red_110: 'hsla(6, 61%, 42%, 1)', // #ab3729
  red_120: 'hsla(6, 74%, 35%, 1)', // #992517

  orange_60: 'hsla(32, 100%, 81%, 1)', // #ffd2a0
  orange_80: 'hsla(32, 100%, 60%, 1)', // #ffa235
  orange_100: 'hsla(33, 100%, 50%, 1)', // #ff8c00
  orange_130: 'hsla(32, 100%, 25%, 1)', // #804500

  subtitle_white: 'hsla(0, 0%, 98%, 1)', // #fafafa
  subtitle_yellow: 'hsla(60, 100%, 50%, 1)', // #FFFF00
  subtitle_green: 'hsla(117, 100%, 39%, 1)', // #0AC700
  subtitle_cyan: 'hsla(180, 100%, 50%, 1)', // #00FFFF
  subtitle_magenta: 'hsla(300, 100%, 50%, 1)', // #FF00FF
  subtitle_red: 'hsla(0, 91%, 45%, 1)', // #DC0A0A
  subtitle_black: 'hsla(0, 0%, 0%, 1)', // #000000
  // subtitle_orange: 'hsla(40, 100%, 50%, 1)', // #FFAA00
}

const colorConverter = (colorType, paletteEntry) => {
  const object = {}
  Object.keys(palette)
    .filter(key => key.includes('_'))
    .filter(key => key.startsWith(paletteEntry))
    .forEach(filtered => {
      object[`${colorType}_${filtered.split('_')[1]}`] = palette[filtered]
    })
  return object
}

const primary = colorConverter('primary', 'teal')
const secondary = colorConverter('secondary', 'blue')
const success = colorConverter('success', 'green')
const error = colorConverter('error', 'red')
const warning = colorConverter('warning', 'orange')

const colors = {
  ...palette,
  clear: 'hsla(0, 0%, 100%, 0)',
  clearFromBlack: 'hsla(0, 0%, 0%, 0)',

  bg: palette.neutral_300,

  // Colors

  ...primary,
  ...secondary,
  ...success,
  ...error,
  ...warning,

  primary: palette.teal_100,
  secondary: palette.blue_100,
  tertiary: palette.blue,
  error: palette.scarletRed,
  buttonBG: palette.teal_100,

  primaryOpacity: palette.lyftaTurquoiseOpacity,
  primaryLightHover: palette.tealLightBlue,

  tableRowHover: palette.teal_60,
  tableRowBottomBorder: palette.neutral_400,

  formLabel: palette.lighterPurple,
  formUnderline: palette.lighterPurple,

  black_600_50: opacify(-(1 - 0.5), palette.black_600),

  // Fonts
  font: {
    primary: palette.black,
    accent: palette.teal_130,
    secondary: palette.tealLightBlue,
    link: palette.teal_100,
    tertiary: palette.darkGray,
    button: palette.lightGray,
  },

  sidebarBackground: palette.darkBrown,
  sidebarAccent: palette.grayMedium,
  sidebarText: palette.neutral_100,

  tableActionPrimaryButtonText: palette.neutral_100,
  tableActionPrimaryButtonBorder: palette.blueLotus,
}

colors.whiteHover = darken(0.05, colors.white)
colors.whiteActive = darken(0.1, colors.white)

colors.primaryHover = darken(0.01, colors.primary)
colors.primaryActive = palette.teal_130

colors.black_600_12 = opacify(-(1 - 0.12), colors.black_600)
colors.black_600_14 = opacify(-(1 - 0.14), colors.black_600)
colors.black_600_20 = opacify(-(1 - 0.2), colors.black_600)
colors.black_600_25 = opacify(-(1 - 0.25), colors.black_600)

colors.primary_60_50 = opacify(-(1 - 0.5), colors.primary_60)
colors.error_60_50 = opacify(-(1 - 0.5), colors.error_60)

export default colors
