/* eslint-disable */
import '../App.css'

import {
  ROCKET_LOADING_STORYWORLD_ID,
  api,
  appRoot,
  appUrls,
  storyWorldRoot,
  studentWebUrl,
  unityUrl,
} from '@lyfta/components-config'
import { Card5 } from '@lyfta/components-content'
import { setStudentLessonID } from '@lyfta/components-data/src/Store/Actions/auth'
import {
  clearResourceId,
  logOut,
} from '@lyfta/components-data/src/Store/Actions/viewer'
import { getTranslation } from '@lyfta/components-i18n'
import { loadLesson } from '@lyfta/components-student/src/Store/Actions/lessons'
import { questTrackerData } from '@lyfta/components-student/src/Store/Actions/questTracker'
import {
  back,
  muted,
  overlay,
  quest,
  rotation,
  scene,
  zoom,
} from '@lyfta/components-student/src/Store/Actions/ui'
import { Modal, TEACHER_ROLE, USER_TYPES } from '@lyfta/components-ui'
import { openBookWebinar } from '@lyfta/components-ui/src/Services/Utils'
import * as Sentry from '@sentry/react'
import jwtDecode from 'jwt-decode'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Unity, { UnityContent } from 'react-unity-webgl'
import dispatch from 'redux-thunk'

import Loader, {
  earthRestoreTimeMs,
  rocketDepartureTimeMs,
  rocketFlightTimeMs,
} from '../Components/Loader'
import { StudentWebApp } from '../StudentWebApp/StudentWebApp'
import { FakeUnityContent } from './FakeUnityContent'
import { LocaleAwareIframe } from './LocaleAwareIframe'
import Video from './video'

/**
 * @param {Error} e
 */
function logAndCaptureError(e) {
  // eslint-disable-next-line no-console
  console.error(e)
  try {
    Sentry.captureException(e)
  } catch (e2) {
    // ignore
  }
}

/* eslint-disable react/sort-comp */
class UnityApp extends Component {
  constructor(props) {
    super(props)

    this.unityRef = React.createRef()
    this.isReady = false

    this.lastExperience = -1

    this.state = {
      isMutedUI: props.isMutedUI,
      isRotationEnabled: props.isRotationEnabled,
      isGyroEnabled: props.isGyroEnabled,
      documentFocused: false,
      iframeOpen: false,
      isFull: false,
      pageTitle: '',
      progression: 0,
      lastContentFoundId: -1,
      isLoading: true,
      isRocketLoading: false,
      isRocketLoaded: false,
      showUnity: false,
      showWeb: false,
      modalOpen: false,
      content: null,
      iframeUrl: null,
      posterUrl: null,
    }

    this.setupHandlers()

    this.setApiUrl = () =>
      this.unityContent.send('ApiManager', 'ChangeAPI', api.baseUrl)

    this.setDevicePixelRatio = () => {
      const pixelRatio = window.devicePixelRatio || 1
      if (pixelRatio > 1)
        this.unityContent.send(
          'ScreenManager',
          'SetDevicePixelRatio',
          pixelRatio.toString(),
        )
    }

    this.pauseUnity = () => {
      const { isLoading } = this.state
      if (!isLoading) this.unityContent.send('ApiManager', 'PauseUnity', '-1')
    }

    this.reduceUnityFPS = () => {
      const { isLoading } = this.state
      if (!isLoading)
        this.unityContent.send('ApiManager', 'DefaultFrameRate', '1') // custom FPS
    }

    this.throttleUnity = () => {
      const { lesson } = this.props
      const { isLoading } = this.state

      if (lesson && lesson.lessonPlan) {
        // a few options below (sadly setting to 0 does not completely pause it as I hoped)
        if (!isLoading)
          this.unityContent.send('ApiManager', 'ThrottleFrameRate', '1') // 1 FPS
        // this.unityContent.send('ApiManager', 'PauseUnity', '0') // 0 FPS ?
        // this.unityContent.send('ApiManager', 'TargetFrameRate', '10') // custom FPS
      }
    }

    this.enableUnityFramerate = () => {
      const { isLoading } = this.state
      if (!isLoading)
        this.unityContent.send('ApiManager', 'DefaultFrameRate', '1')
    }

    this.setPassphrase = () => {
      localStorage.removeItem('passphrase')
    }

    this.setLanguage = locale => {
      if (!locale) return
      this.unityContent.send('ApiManager', 'SetLanguage', locale)
    }
  }

  componentDidMount = () => {
    window.history.pushState(null, document.title, window.location.href)
    window.addEventListener('popstate', () => {
      window.history.pushState(null, document.title, window.location.href)
    })
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const {
      isFullScreen,
      isBackToGlobe,
      isMutedUI,
      isRotationEnabled,
      isGyroEnabled,
      isMutedDuringTemporaryUnmute,
      zoomInPressed,
      zoomOutPressed,
      lessonsPopupOpenRequested,
      handleLessonsPopupOpen,
      resetCurrentLesson,
      mutedDuringTemporaryUnmuteHandled,
      zoomHandled,
      helpMeFind,
      token,
      webGLPaused,
      webGLReduced,
      showRichMedia,
      hideRichMedia,
      lesson,
      lessonPlan,
      viewerType,
    } = this.props
    const { iframeOpen } = this.state

    if (
      (!lesson && nextProps.lesson) ||
      (!lessonPlan && nextProps.lessonPlan)
    ) {
      this.handleLesson(nextProps.lesson, nextProps.lessonPlan, this.isReady)
    }

    if (nextState.iframeOpen !== iframeOpen) {
      if (nextState.iframeOpen) {
        showRichMedia()
      } else {
        hideRichMedia()
      }
    }

    if (webGLPaused !== nextProps.webGLPaused) {
      if (!webGLPaused) {
        this.throttleUnity()
      } else {
        this.enableUnityFramerate()
      }
    }

    if (webGLReduced !== nextProps.webGLReduced) {
      if (!webGLReduced) {
        this.reduceUnityFPS()
      } else {
        this.enableUnityFramerate()
      }
    }

    if (token !== nextProps.token) {
      this.handleSetString({ key: 'TOKEN', value: nextProps.token })
    }

    if (isMutedUI !== nextProps.isMutedUI) {
      this.toggleUIMuteState(nextProps.isMutedUI)
    }

    if (isRotationEnabled !== nextProps.isRotationEnabled) {
      this.toggleRotationState(nextProps.isRotationEnabled)
    }

    if (isGyroEnabled !== nextProps.isGyroEnabled) {
      this.toggleGyroState(nextProps.isGyroEnabled)
    }

    if (
      isMutedDuringTemporaryUnmute !== nextProps.isMutedDuringTemporaryUnmute
    ) {
      if (nextProps.isMutedDuringTemporaryUnmute) {
        this.unityContent.send('AudioManager', 'SetAudioMuted', 1)
        mutedDuringTemporaryUnmuteHandled()
      }
    }

    if (isFullScreen !== nextProps.isFullScreen) {
      this.requestFullScreen(nextProps.isFullScreen)
    }

    if (lessonsPopupOpenRequested !== nextProps.lessonsPopupOpenRequested) {
      if (nextProps.lessonsPopupOpenRequested) {
        setTimeout(() => {
          if (viewerType !== USER_TYPES.homeStudents) {
            this.unityContent.send('ApiManager', 'ClearFilters')
          }

          this.handleHelpMeFind(-1)
          resetCurrentLesson()

          this.openIframe(
            new URL('./lesson-selection', window.location.href).href,
          )

          handleLessonsPopupOpen()
        }, 1)
      }
    }

    if (zoomInPressed !== nextProps.zoomInPressed) {
      if (nextProps.zoomInPressed) {
        this.unityContent.send('LyftaPlayer', 'ZoomDelta', -10.0)
        zoomHandled()
      }
    }

    if (zoomOutPressed !== nextProps.zoomOutPressed) {
      if (nextProps.zoomOutPressed) {
        this.unityContent.send('LyftaPlayer', 'ZoomDelta', 10.0)
        zoomHandled()
      }
    }

    if (isBackToGlobe !== nextProps.isBackToGlobe) {
      if (nextProps.isBackToGlobe) {
        this.handleBackToGlobe()
      }
    }

    if (helpMeFind !== nextProps.helpMeFind) {
      this.handleHelpMeFind(nextProps.helpMeFind)
    }
  }

  componentWillUnmount = () => {
    // doesn't unmount properly (WebGL side hangs around and audio plays in background on login page, so we reload page)
    // NB this will need revisiting if we ever need to unmount this unity component but remain logged in!
    localStorage.clear()
    document.location.reload()
    // TODO clear better
  }

  setupHandlers = () => {
    if (Boolean(studentWebUrl)) {
      this.unityContent = new FakeUnityContent(studentWebUrl)
      this.state.showWeb = true
    } else {
      this.unityContent = new UnityContent(unityUrl, `${appRoot}UnityLoader.js`)
    }
    this.unityContent.on('OpenWebPage', this.openIframe.bind(this))
    this.unityContent.on('OpenVideo', this.openVideo.bind(this))
    this.unityContent.on('FullScreen', this.fullScreen.bind(this))
    this.unityContent.on('OpenUrlExternal', this.openExternalUrl.bind(this))
    this.unityContent.on('Ready', this.handleReady.bind(this))
    this.unityContent.on(
      'LyftaPlayerReady',
      this.handleLyftaPlayerReady.bind(this),
    )
    this.unityContent.on(
      'UnlicensedStoryworldClicked',
      this.handleUnlicensedStoryworldClicked.bind(this),
    )
    this.unityContent.on(
      'IsDelayedPinClick',
      this.handleIsDelayedPinClick.bind(this),
    )
    this.unityContent.on('OpenLoader', this.openLoader.bind(this))
    this.unityContent.on('SendLoadProgress', this.sendLoadProgress.bind(this))
    this.unityContent.on('LoadComplete', this.loadComplete.bind(this))
    this.unityContent.on('UpdateExperience', this.updateExperience.bind(this))
    this.unityContent.on('GetVersion', this.getVersion.bind(this))
    this.unityContent.on(
      'TemporaryUnmuteStarted',
      this.temporaryUnmuteStarted.bind(this),
    )
    this.unityContent.on(
      'TemporaryUnmuteFinished',
      this.temporaryUnmuteFinished.bind(this),
    )
    this.unityContent.on('RotationStarted', this.rotationStarted.bind(this))
    this.unityContent.on('RotationEnded', this.rotationEnded.bind(this))

    if (typeof Storage !== 'undefined') {
      this.unityContent.on('SetString', this.handleSetString.bind(this))
      this.unityContent.on('GetString', this.handleGetString.bind(this))
      this.unityContent.on('Logout', () => {
        localStorage.clear()
      })
    }

    this.handleCloseIframe = this.handleCloseIframe.bind(this)

    window.addEventListener('paste', this.handlePaste.bind(this))
    window.addEventListener('message', this.handleFrameTasks.bind(this))

    this.unityContent.on('progress', progression => {
      this.setState({
        progression,
      })
    })

    this.unityContent.on('loaded', this.handleLoaded.bind(this))
    this.unityContent.on('loginFailure', this.handleLoginFailure.bind(this))
    this.unityContent.on('VideoProgress', this.handleVideoProgress.bind(this))
    this.unityContent.on(
      'FrameClose',
      this.handleHelpMeFoundOnFrameClose.bind(this),
    )
    this.unityContent.on(
      'HighlightGlobeForHelpMeFind',
      this.handleHighlightGlobeForHelpMeFind.bind(this),
    )
    this.unityContent.on('IsInScene', this.isInScene.bind(this))
    this.unityContent.on('IsNotInScene', this.isNotInScene.bind(this))
    window.sendUnity = (...args) => {
      this.unityContent.send(...args)
    }

    this.state.showUnity = true
  }

  handleLoaded = () => {
    if (this.state.showWeb) {
      // then we need to do interval-based focus checking...
      // https://stackoverflow.com/questions/24619114/identifying-window-focus-blur-events-with-iframes
      setInterval(() => {
        const hasFocus = document.hasFocus()
        const { documentFocused } = this.state
        if (documentFocused !== hasFocus) {
          this.setState(
            {
              documentFocused: hasFocus,
            },
            this.updateAudioMute,
          )
        }
      }, 100)
      return
    }
    this.fixDeviceResolution()
    window.addEventListener('resize', this.fixDeviceResolution.bind(this))
    window.addEventListener('blur', () => {
      this.setState(
        {
          documentFocused: false,
        },
        this.updateAudioMute,
      )
    })
    window.addEventListener('focus', () => {
      this.setState(
        {
          documentFocused: true,
        },
        this.updateAudioMute,
      )
    })

    // Now we can for example hide the loading overlay.
    this.setState(
      {
        isLoading: false,
        isRocketLoading: false,
        isRocketLoaded: false,
        documentFocused: document.hasFocus,
      },
      this.updateAudioMute,
    )
  }

  handleLoginFailure = () => {
    const { logOut } = this.props

    alert(getTranslation('auth.loginFailure'))

    logOut()
  }

  isInScene = () => {
    const { isInScene } = this.props
    isInScene()
  }

  isNotInScene = () => {
    const { isNotInScene } = this.props
    isNotInScene()
  }

  handleGetString = key => {
    const { token } = this.props

    if (key === 'usertoken') {
      return localStorage.getItem(key)
    }

    if (key === 'TOKEN') {
      if (token) {
        const decodedToken = jwtDecode(token)
        const { exp } = decodedToken

        const currentTime = Date.now() / 1000
        if (exp > currentTime) {
          return token
        }
        localStorage.clear()
      }
    }

    // this will then force a logout from the student app as the empty string is an invalid token.
    return ''
  }

  handleContentFind = () => {
    // if (contentId < 0) return
  }

  handleHelpMeFind = contentId => {
    if (contentId < 0) {
      this.unityContent.send('ApiManager', 'StopHelpMeFind', '-1')
      return
    }

    const { handleHelpMeFound } = this.props
    const { showUnity } = this.state

    // this.unityContent.send('ApiManager', 'HelpMeFind', '515') // Beekeeper test example
    this.unityContent.send('ApiManager', 'HelpMeFind', contentId.toString())

    const { handleOverlayClose } = this.props
    handleOverlayClose()

    this.setState(
      {
        iframeOpen: false,
        video: false,
      },
      this.updateMuteAndRotation,
    )

    if (!showUnity) {
      handleHelpMeFound(contentId)
      this.unityContent.send('ApiManager', 'StopHelpMeFind', '-1')
    }
  }

  updateMuteAndRotation = () => {
    this.updateAudioMute()
    this.updateRotation()
  }

  updateAudioMute = () => {
    const {
      isMutedUI,
      documentFocused,
      iframeOpen,
      isRocketLoading,
    } = this.state
    const audioShouldBeMuted =
      isMutedUI || iframeOpen || !documentFocused || isRocketLoading

    try {
      this.unityContent.send(
        'AudioManager',
        'SetAudioMuted',
        audioShouldBeMuted ? 1 : 0,
      )
    } catch (e) {
      // ignore the error, it seems to cause a crash otherwise...
    }
  }

  updateRotation = () => {
    const { isRotationEnabled, isGyroEnabled, iframeOpen } = this.state
    const rotationShouldBeEnabled = isRotationEnabled && !iframeOpen
    const gyroShouldBeEnabled = isGyroEnabled && !iframeOpen

    this.unityContent.send(
      'LyftaPlayer',
      'SetRotationEnabled',
      rotationShouldBeEnabled ? 1 : 0,
    )

    if (gyroShouldBeEnabled) {
      const gyroListener = event => {
        this.unityContent.send('LyftaPlayer', 'Gyro', {
          alpha: event.alpha,
          beta: event.beta,
          gamma: event.gamma,
        })
      }

      window.addEventListener('deviceorientation', gyroListener)

      this.cleanupGyroListener = () => {
        window.removeEventListener('deviceorientation', gyroListener)
      }
    } else {
      if (this.cleanupGyroListener) {
        this.cleanupGyroListener()
        this.cleanupGyroListener = null
      }
    }

    this.unityContent.send(
      'LyftaPlayer',
      'SetGyroEnabled',
      gyroShouldBeEnabled ? 1 : 0,
    )
  }

  toggleUIMuteState = isMutedUI => {
    this.setState(
      {
        isMutedUI,
      },
      this.updateAudioMute,
    )
  }

  toggleRotationState = isRotationEnabled => {
    this.setState(
      {
        isRotationEnabled,
      },
      this.updateRotation,
    )
  }

  toggleGyroState = isGyroEnabled => {
    this.setState(
      {
        isGyroEnabled,
      },
      this.updateRotation,
    )
  }

  openLoader = () => {
    this.setState({
      isLoading: true,
      progression: 0,
    })
  }

  loadComplete = () => {
    const { isLoading, isRocketLoading } = this.state

    if (isLoading) {
      if (isRocketLoading) {
        this.setState({
          isRocketLoaded: true,
        })

        setTimeout(() => {
          this.setState(
            {
              isRocketLoading: false,
              isRocketLoaded: false,
              isLoading: false,
            },
            this.updateAudioMute,
          )
        }, rocketDepartureTimeMs + earthRestoreTimeMs + 500)

        return
      }

      this.setState(
        {
          isRocketLoading: false,
          isRocketLoaded: false,
          isLoading: false,
        },
        this.updateAudioMute,
      )
    }
  }

  sendLoadProgress = p => {
    let progression = p
    progression += 0.1
    if (progression > 1) progression = 1
    this.setState({
      progression,
    })
  }

  handleReady = () => {
    this.isReady = true

    this.setApiUrl()
    this.setDevicePixelRatio()
    this.throttleUnity()

    setTimeout(() => {
      this.enableUnityFramerate()
      this.setLanguage(locale)
      this.setDevicePixelRatio()
    }, 8000)

    const { locale, resourceId, lesson, lessonPlan } = this.props
    this.setLanguage(locale)

    this.handleLesson(lesson, lessonPlan, this.isReady)

    if (resourceId) {
      this.enableUnityFramerate()
      this.setState({
        isLoading: true,
      })
      setTimeout(
        () => {
          this.enableUnityFramerate()
          // because Unity saying it's "ready" does not mean it's READY
          this.unityContent.send('ApiManager', 'OpenContent', resourceId)
          this.setLanguage(locale)
        },
        this.state.showWeb ? 0 : 3000,
      )
    }

    if (this.state.showWeb) {
      const { helpMeFind, handleHelpMeFound } = this.props

      if (helpMeFind !== '-1') {
        handleHelpMeFound('-1')
      }
    }

    return true
  }

  handleLyftaPlayerReady = () => {
    this.updateMuteAndRotation()

    this.unityContent.send('ApiManager', 'EnableShowingUnlicensedContent')

    const { lesson, lessonPlan } = this.props
    const { showWeb } = this.state

    if (showWeb) {
      this.handleLesson(lesson, lessonPlan, this.isReady)
    }
  }

  updateModalState = (open, content) => {
    this.setState({
      modalOpen: open,
      content,
    })
  }

  handleUnlicensedStoryworldClicked = ([param, param2]) => {
    try {
      const parsed = JSON.parse(param)
      const all = JSON.parse(param2)
      const data = Array.isArray(all) ? all : all.data
      const itemFound = data.find(item => {
        if (item.data) {
          return `${item.data.id}` === `${parsed.id}`
        } else {
          return `${item.id}` === `${parsed.id}`
        }
      })
      const item = {
        ...itemFound.attributes,
        ...parsed.attributes,
        id: parsed.id,
      }
      this.updateModalState(true, item)
    } catch (e) {
      logAndCaptureError(e)
    }
  }

  handleIsDelayedPinClick = data => {
    let dataObject = JSON.parse(data)
    if (typeof dataObject === 'number') {
      dataObject = {
        id: data,
        action: 'initialClick',
      }
    }
    const pinId = dataObject.id
    if (`${pinId}` === `${ROCKET_LOADING_STORYWORLD_ID}`) {
      if (dataObject.action === 'initialClick') {
        this.setState(
          {
            isLoading: true,
            isRocketLoading: true,
            progression: 0.1,
            isRocketLoaded: false,
          },
          this.updateAudioMute,
        )

        setTimeout(() => {
          this.unityContent.send('ApiManager', 'ClickPin', `${pinId}`)
        }, rocketFlightTimeMs + 500)
      }

      return true
    }

    return false
  }

  handleSetString = ({ key, value }) => {
    localStorage.setItem(key, value)
  }

  requestFullScreen = requester => {
    try {
      const elem = document.documentElement // Make the body go full screen.

      if (requester) {
        this.unityContent.send('SetFullScreenState', true)

        if (elem.requestFullscreen) {
          elem.requestFullscreen().catch(err => console.error(err))
        } else if (elem.mozRequestFullScreen) {
          /* Firefox */
          elem.mozRequestFullScreen()
        } else if (elem.webkitRequestFullscreen) {
          /* Chrome, Safari & Opera */
          elem.webkitRequestFullscreen()
        } else if (elem.msRequestFullscreen) {
          /* IE/Edge */
          elem.msRequestFullscreen()
        }
      } else if (document.exitFullscreen) {
        this.unityContent.send('SetFullScreenState', false)
        document.exitFullscreen().catch(err => console.error(err))
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    } catch (e) {
      // ignore
    }
  }

  fixDeviceResolution = event => {
    if (event) {
      event.stopPropagation()
    }

    const canvas = document.getElementsByTagName('canvas')[0]
    const desiredCSSWidth = window.innerWidth
    const desiredCSSHeight = window.innerHeight
    const pixelRatio = window.devicePixelRatio || 1

    this.setDevicePixelRatio()

    canvas.width = desiredCSSWidth * pixelRatio
    canvas.height = desiredCSSHeight * pixelRatio

    canvas.style.width = `${desiredCSSWidth}px`
    canvas.style.height = `${desiredCSSHeight}px`
  }

  handleFrameTasks = message => {
    const {
      onLoadLesson,
      onSetStudentLessonId,
      onSetIsTeachingInFrontOfClass,
      onResumeWebGL,
      onSelectLesson,
      showQuest,
    } = this.props

    if (message.data === 'close') {
      this.handleCloseIframe()
      this.handleHelpMeFoundOnFrameClose()
    } else if (
      message &&
      message.data &&
      message.data.request === 'lesson-selection'
    ) {
      const { lessId } = message.data
      this.handleCloseIframe()

      if (lessId > 0) {
        showQuest()
        onLoadLesson(lessId)
        onSetStudentLessonId(lessId)
        onSetIsTeachingInFrontOfClass(true)
      } else {
        onSetStudentLessonId(null)
        onSetIsTeachingInFrontOfClass(false)
      }
      onResumeWebGL()
      onSelectLesson(lessId)
    }
  }

  handlePaste = e => {
    this.unityContent.send(
      'ClipboardHandler',
      'Paste',
      e.clipboardData.getData('Text'),
    )
  }

  fullScreen = () => {
    const { isFull } = this.state

    document.onmouseup = () => {
      this.setState({
        isFull: !isFull,
      })
      document.onmouseup = null
    }
  }

  onRedirectUser = (subscribed = false) => {
    if (subscribed) window.open(' https://www.lyfta.com/contact-us', '_blank')
    else window.open('https://www.lyfta.com/training', '_blank')
  }

  handleVideoProgress = progressEvent => {
    const { lastContentFoundId } = this.state
    const { helpMeFind, findContentId, handleHelpMeFound } = this.props

    const helpMeFindIdNum = parseInt(helpMeFind, 10)
    const findContentIdNum = parseInt(findContentId, 10)

    if (lastContentFoundId === -1) return
    if (progressEvent.played > 0.5) {
      if (
        lastContentFoundId === helpMeFindIdNum ||
        lastContentFoundId === findContentIdNum
      ) {
        handleHelpMeFound(lastContentFoundId.toString())
        this.unityContent.send('ApiManager', 'StopHelpMeFind', '-1')

        this.setState({
          lastContentFoundId: -1,
        })
      }
    }
  }

  getVersion = () => {
    // Version X:
    // - write changes here
    // Version 5:
    // - Added IsDelayedPinClick
    // Version 4:
    // - Added EnableShowingUnlicensedContent
    // Version 3:
    // - Added RotationStarted
    // - Added RotationEnded
    // Version 2:
    // - Added TemporaryUnmuteStarted
    // - Added TemporaryUnmuteFinished
    return 5
  }

  temporaryUnmuteStarted = () => {
    const { temporaryUnmuteStarted } = this.props
    temporaryUnmuteStarted()
  }

  temporaryUnmuteFinished = () => {
    const { temporaryUnmuteEnded } = this.props
    temporaryUnmuteEnded()
  }

  rotationStarted = () => {
    const { rotationStarted } = this.props
    rotationStarted()
  }

  rotationEnded = () => {
    const { rotationEnded } = this.props
    rotationEnded()
  }

  updateExperience = experienceBodyJSONString => {
    const {
      helpMeFind,
      findContentId,
      handleHelpMeFound,
      resourceId,
      clearPreviewResourceId,
    } = this.props
    let experienceBody = {}
    if (experienceBodyJSONString)
      experienceBody = JSON.parse(experienceBodyJSONString)

    let dataId = parseInt(experienceBody.data.relationships.entity.data.id, 10)

    let type = null
    if (experienceBody.data.contentAttributes) {
      // eslint-disable-next-line prefer-destructuring
      type = experienceBody.data.contentAttributes.type
    }

    if (type === 'video' || type === 'article') {
      // do not mark as complete yet, we need to make sure they watched/viewed it
      this.setState({
        lastContentFoundId: dataId,
      })
    } else {
      if (dataId === 292296397) dataId = 574 // Beekeeper 360 fix
      if (dataId === 576) dataId = 559
      if (dataId === 799322663) dataId = 476 // Habbiba's home 360 fix

      // eslint-disable-next-line eqeqeq
      // if (this.lastExperience == dataId && dataId == 515) dataId = 574
      this.lastExperience = dataId
      const helpMeFindIdNum = parseInt(helpMeFind, 10)
      const findContentIdNum = parseInt(findContentId, 10)
      const resourceIdNum = parseInt(resourceId, 10)

      if (dataId === helpMeFindIdNum || dataId === findContentIdNum) {
        handleHelpMeFound(dataId.toString())
        this.unityContent.send('ApiManager', 'StopHelpMeFind', '-1')
      }

      if (dataId === resourceIdNum) {
        // NOTE: if the teacher already has a lesson started then, once the below has run, the quest tracker immediately pops up and obscures the preview
        // (not a lot can be done about that unless perhaps automatically stopping the lesson on the teacher app side before opening preview)
        clearPreviewResourceId()
        this.unityContent.send('ApiManager', 'StopHelpMeFind', '-1')
      }

      this.setState({
        lastContentFoundId: -1,
      })
    }
  }

  openExternalUrl = url => {
    // document.onmouseup = function () {
    window.open(url)
    // document.onmouseup = null
    // }
  }

  openIframe = url => {
    const { handleOverlayOpen } = this.props

    if (url.indexOf('youtube') > -1) {
      document.onmouseup = () => {
        window.open(url)
        document.onmouseup = null
      }
    } else {
      handleOverlayOpen()
      this.setState(
        {
          iframeOpen: true,
          video: false,
          iframeUrl: url,
        },
        this.updateMuteAndRotation,
      )
    }
  }

  /**
   *
   * @param {string} url
   * @param {string=} posterUrl
   */
  openVideo = (url, posterUrl) => {
    const { handleOverlayClose } = this.props
    const iframeUrl = url.replace(
      'https://s3-eu-west-1.amazonaws.com/lyfta-storyworlds-live/',
      storyWorldRoot,
    )

    handleOverlayClose()
    this.setState(
      {
        iframeOpen: true,
        video: true,
        iframeUrl,
        posterUrl,
      },
      this.updateMuteAndRotation,
    )
  }

  handleHighlightGlobeForHelpMeFind = () => {
    const { setBackButtonHighlight } = this.props

    setBackButtonHighlight(true)
  }

  handleHelpMeFoundOnFrameClose = () => {
    const { lastContentFoundId } = this.state

    const { helpMeFind, findContentId, handleHelpMeFound } = this.props

    const helpMeFindIdNum = parseInt(helpMeFind, 10)
    const findContentIdNum = parseInt(findContentId, 10)

    if (lastContentFoundId === -1) return
    if (
      lastContentFoundId === helpMeFindIdNum ||
      lastContentFoundId === findContentIdNum
    ) {
      handleHelpMeFound(helpMeFind)
      this.unityContent.send('ApiManager', 'StopHelpMeFind', '-1')

      this.setState({
        lastContentFoundId: -1,
      })
    }
  }
  handleCloseIframe = () => {
    const { handleOverlayClose } = this.props
    handleOverlayClose()

    this.setState(
      {
        iframeOpen: false,
        iframeUrl: 'about:blank',
      },
      () => {
        // give focus back to Unity
        window.focus()

        this.unityContent.send('ScreenManager', 'EnableAudio')

        this.updateMuteAndRotation()
      },
    )
  }

  respondToFullScreenChange = isFull => {
    this.setState({ isFull })
    this.unityContent.send('SetFullScreenState', isFull)
  }

  onReady = () => {}

  onReceiveMessage = msg => {
    const { lastContentFoundId } = this.state

    if (!msg.data) return

    let msgData = {}
    try {
      msgData = JSON.parse(msg.data)
    } catch (err) {
      // TODO
    }

    if (lastContentFoundId > -1 && msgData.scrollY) {
      const { helpMeFind, findContentId } = this.props
      const scrollYPct = msgData.scrollY
      const helpMeFindIdNum = parseInt(helpMeFind, 10)
      const findContentIdNum = parseInt(findContentId, 10)

      if (scrollYPct > 75) {
        if (
          lastContentFoundId === helpMeFindIdNum ||
          lastContentFoundId === findContentIdNum
        ) {
          // TODO mark complete. Revisit when article scroll behaviour can work with RMA videos, et al
        }
      }
    }
  }

  handleBackToGlobe = () => {
    const {
      handleOverlayClose,
      setBackButtonHighlight,
      isBackToGlobeHighlighted,
      handleBackButton,
    } = this.props
    handleOverlayClose()

    this.unityContent.send('ScreenManager', 'BackToGlobe')
    if (!isBackToGlobeHighlighted) {
      // cancel any existing "help me find it" instruction
      this.unityContent.send('ApiManager', 'StopHelpMeFind', '-1')
    } else {
      setBackButtonHighlight(false)
    }

    this.setState(
      {
        iframeOpen: false,
        video: false,
      },
      this.updateMuteAndRotation,
    )

    handleBackButton()
  }

  handleLesson(lesson, lessonPlan, isReady) {
    if (!lesson) {
      const { viewerType } = this.props
      if (viewerType === USER_TYPES.homeStudents) {
        window.location.href = appUrls.student(
          window.location,
          '',
          'lesson-selection',
        )

        return
      }
    }

    const { isLoading } = this.state

    // make sure Unity has finished loading before we send these over!
    if (isLoading && !(this.state.showWeb && isReady)) {
      return
    }

    let chosenLessonPlan = null
    if (lessonPlan) {
      chosenLessonPlan = lessonPlan
    } else {
      if (lesson) {
        const { state: lessonState } = lesson

        if (lessonState === 'archived') {
          return
        }

        chosenLessonPlan = lesson.lessonPlan
      }
    }

    if (!chosenLessonPlan) {
      return
    }

    const { activities } = chosenLessonPlan

    const contentIds = []
    const xmlIds = []
    activities.forEach(activity => {
      const { tasks } = activity
      tasks.forEach(task => {
        const { type, content } = task
        if (type === 'content') {
          const { id, parent: contentParent, xmlId } = content
          contentIds.push(parseInt(id, 10))
          xmlIds.push(parseInt(xmlId, 10))
          if (contentParent) {
            const { xmlId: parentXMLId } = contentParent
            if (parentXMLId !== null && parentXMLId !== undefined) {
              xmlIds.push(parseInt(parentXMLId, 10))
            }
          }
        }
      })
    })

    if (contentIds.length > 0) {
      this.unityContent.send(
        'ApiManager',
        'FilterContentIds',
        JSON.stringify(contentIds),
      )
    }
    if (xmlIds.length > 0) {
      this.unityContent.send(
        'ApiManager',
        'FilterXmlIds',
        JSON.stringify(xmlIds),
      )
    }
  }

  renderIFrame = () => {
    const { iframeUrl, video } = this.state
    const attributes = {
      className: 'myframe',
      src: iframeUrl,
      width: '100%',
      height: '100%',
      frameBorder: 0,
    }

    if (!video) {
      attributes.className += ' myframewithbackground'
    }

    const { locale } = this.props

    // the postMessage data you want to send to your iframe
    // it will be send after the iframe has loaded
    const postMessageData = 'windowMode=web'

    return (
      <LocaleAwareIframe
        attributes={attributes}
        postMessageData={postMessageData}
        handleReady={this.onReady}
        handleReceiveMessage={this.onReceiveMessage}
        locale={locale}
      />
    )
  }

  renderVideo = () => {
    const { iframeUrl, pageTitle, posterUrl } = this.state
    const { locale } = this.props

    return (
      <Video
        locale={locale}
        iframeUrl={iframeUrl}
        posterUrl={posterUrl}
        pageTitle={pageTitle}
        onProgress={this.handleVideoProgress}
        onClose={this.handleCloseIframe}
      />
    )
  }

  render = () => {
    const {
      isLoading,
      isRocketLoading,
      isRocketLoaded,
      progression,
      showUnity,
      showWeb,
      iframeOpen,
      video,
      modalOpen,
      content,
      isMutedUI,
      documentFocused,
    } = this.state

    const { isOverlayOpen, viewer } = this.props

    let progPlus = progression + 0.05
    if (progPlus > 1) progPlus = 1
    const isLeader =
      viewer.jobRole === TEACHER_ROLE.executive ||
      viewer.jobRole === TEACHER_ROLE.executive

    const audioShouldBeMuted = isMutedUI || iframeOpen || !documentFocused

    return (
      <div
        className="App"
        id="unity-app-container"
        style={{ userSelect: 'none' }}
      >
        {isLoading && showUnity && (
          <Loader
            progression={progPlus}
            isRocketLoading={isRocketLoading}
            audioShouldBeMuted={audioShouldBeMuted}
            isRocketLoaded={isRocketLoaded}
          />
        )}
        {showUnity &&
          (showWeb ? (
            <StudentWebApp
              ref={this.unityRef}
              unityContent={this.unityContent}
            />
          ) : (
            <Unity ref={this.unityRef} unityContent={this.unityContent} />
          ))}
        {iframeOpen && (
          <div>
            {isOverlayOpen && this.renderIFrame()}
            {video && this.renderVideo()}
          </div>
        )}
        {
          <Modal
            hideClose
            onDismissModal={() => this.updateModalState(false, null)}
            showFooter={false}
            isOpen={modalOpen}
            fitContent
            modalStyle={{
              wrapperContainer: {
                margin: '0px',
                padding: '0px',
              },
            }}
          >
            <Card5
              item={content}
              viewer={viewer}
              onView={() =>
                isLeader
                  ? this.openExternalUrl(
                      ` https://meetings.hubspot.com/rahul95/introductory-meeting-with-lyfta?firstname=${viewer.firstName}&lastname=${viewer.lastName}&email=${viewer.email}`,
                    )
                  : openBookWebinar()
              }
              onDismissModal={() => this.updateModalState(false, null)}
            />
          </Modal>
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  language: state.payload,
  loggedIn: state.loggedIn,
})

const mapDispatchToProps = {
  clearPreviewResourceId: clearResourceId,
  handleHelpMeFind: quest.helpMeFind,
  handleHelpMeFound: quest.helpMeFound,
  handleOverlayClose: overlay.hide,
  handleOverlayOpen: overlay.show,
  handleBackButton: back.hide,
  isInScene: scene.isInScene,
  isNotInScene: scene.isNotInScene,
  setBackButtonHighlight: back.highlight,
  hideRichMedia: quest.hideRichMedia,
  showQuest: quest.show,
  handleLessonsPopupOpen: quest.handleLessonsPopupOpen,
  mutedDuringTemporaryUnmuteHandled: muted.mutedDuringTemporaryUnmuteHandled,
  resetCurrentLesson: questTrackerData.resetCurrentLesson,
  rotationEnded: rotation.rotationEnded,
  rotationStarted: rotation.rotationStarted,
  showRichMedia: quest.showRichMedia,
  temporaryUnmuteEnded: muted.temporaryUnmuteEnded,
  temporaryUnmuteStarted: muted.temporaryUnmuteStarted,
  zoomHandled: zoom.handled,
  onLoadLesson: loadLesson,
  onPauseWebGL: quest.pauseWebGL,
  onResumeWebGL: quest.resumeWebGL,
  onSelectLesson: quest.questSelect,
  onSetIsTeachingInFrontOfClass: questTrackerData.setIsTeachingInFrontOfClass,
  onSetStudentLessonId: setStudentLessonID,
  logOut: logOut,
  dispatch,
}

UnityApp.defaultProps = {
  findContentId: null,
  helpMeFind: null,
  lesson: null,
  lessonPlan: null,
  resourceId: null,
}

UnityApp.propTypes = {
  clearPreviewResourceId: PropTypes.func.isRequired,
  findContentId: PropTypes.string,
  handleHelpMeFound: PropTypes.func.isRequired,
  handleLessonsPopupOpen: PropTypes.func.isRequired,
  handleOverlayClose: PropTypes.func.isRequired,
  handleOverlayOpen: PropTypes.func.isRequired,
  handleBackButton: PropTypes.func.isRequired,
  setBackButtonHighlight: PropTypes.func.isRequired,
  helpMeFind: PropTypes.string,
  hideRichMedia: PropTypes.func.isRequired,
  isBackToGlobe: PropTypes.bool.isRequired,
  isBackToGlobeHighlighted: PropTypes.bool.isRequired,
  isFullScreen: PropTypes.bool.isRequired,
  isInScene: PropTypes.func.isRequired,
  isNotInScene: PropTypes.func.isRequired,
  isMutedDuringTemporaryUnmute: PropTypes.bool.isRequired,
  isMutedUI: PropTypes.bool.isRequired,
  isOverlayOpen: PropTypes.bool.isRequired,
  isRotationEnabled: PropTypes.bool.isRequired,
  lesson: PropTypes.object,
  lessonPlan: PropTypes.object,
  lessonsPopupOpenRequested: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  logOut: PropTypes.func.isRequired,
  mutedDuringTemporaryUnmuteHandled: PropTypes.func.isRequired,
  onLoadLesson: PropTypes.func.isRequired,
  onResumeWebGL: PropTypes.func.isRequired,
  resetCurrentLesson: PropTypes.func.isRequired,
  resourceId: PropTypes.string,
  rotationEnded: PropTypes.func.isRequired,
  rotationStarted: PropTypes.func.isRequired,
  showQuest: PropTypes.func.isRequired,
  showRichMedia: PropTypes.func.isRequired,
  temporaryUnmuteEnded: PropTypes.func.isRequired,
  temporaryUnmuteStarted: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  viewerType: PropTypes.string.isRequired,
  webGLPaused: PropTypes.bool.isRequired,
  webGLReduced: PropTypes.bool.isRequired,
  zoomHandled: PropTypes.func.isRequired,
  zoomInPressed: PropTypes.bool.isRequired,
  zoomOutPressed: PropTypes.bool.isRequired,
  onSelectLesson: PropTypes.func.isRequired,
  onSetIsTeachingInFrontOfClass: PropTypes.func.isRequired,
  onSetStudentLessonId: PropTypes.func.isRequired,
}

const UnityAppContainer = connect(mapStateToProps, mapDispatchToProps)(UnityApp)
export default UnityAppContainer
