import { getTranslation } from '@lyfta/components-i18n'
import { Icon } from '@lyfta/components-ui'
import PT from 'prop-types'
import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import { MascotDefault, MascotHover } from '../../../../Assets/Menu'
import { drawer } from '../../../../Store/Actions/ui'
import { QuestOctopus } from './styles'

const MenuController = ({ animation, ...props }) => {
  const dispatch = useDispatch()
  const openDrawer = useCallback(() => {
    dispatch(drawer.show())
  })
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseOver = () => {
    setIsHovered(true)
  }

  const handleMouseOut = () => {
    setIsHovered(false)
  }
  return (
    <QuestOctopus
      animation={animation}
      {...props}
      id="btn-octopus-open-activities-list"
      leadingIcon={
        !isHovered ? (
          <Icon glyph={MascotDefault} height={44} width={44} />
        ) : (
          <Icon glyph={MascotHover} height={44} width={44} />
        )
      }
      motionButton
      title={getTranslation('header.openTaskMenu')}
      type="icon"
      onClick={openDrawer}
      onMouseEnter={() => handleMouseOver()}
      onMouseLeave={() => handleMouseOut()}
    >
      {getTranslation('header.openTaskMenu')}
    </QuestOctopus>
  )
}
MenuController.propTypes = {
  animation: PT.object.isRequired,
}
export default MenuController
