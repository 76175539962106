/* eslint-disable max-lines-per-function,max-lines */
import 'video.js/dist/video-js.css'
import 'videojs-hotkeys'
import '@viostream/videojs-chapters/dist/videojs-chapters.css'

import './video-styling.css'

import PropTypes from 'prop-types'
import React, { useEffect, useLayoutEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import videojs from 'video.js'

import { SettingsSvg } from '../../Assets/Svg'
import { Icon } from '../Icon'
import ChapterMarkersProgressBarControl from './chapterMarkersProgressBar'
import SettingsBridgeComponent from './settings/settingsBridgeComponent'

export const VideoJSPlayer = ({
  options,
  onReady,
  videoProps,
  containerProps,
  onProgress,
}) => {
  /**
   * @type {React.MutableRefObject<HTMLVideoElement | null>}
   */
  const videoRef = useRef(null)
  /**
   * @type {React.MutableRefObject<import('video.js').VideoJsPlayer | null>}
   */
  const playerRef = useRef(null)

  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current && videoRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.

      playerRef.current = videojs(videoRef.current, options, () => {
        const player = playerRef.current

        videojs.log('player is ready')
        if (onReady) {
          onReady(player)

          if (onProgress) {
            onProgress({
              played: 0,
            })
          }
        }

        let latestTime = `0.00`

        videojs.registerComponent(
          'settingsBridgeComponent',
          SettingsBridgeComponent,
        )

        videojs.log('player set BridgeComponent')

        const ModalDialog = videojs.getComponent('ModalDialog')

        const settingsModal = new ModalDialog(player, {
          children: ['settingsBridgeComponent'],
          // fillAlways: true,
          temporary: false,
          uncloseable: true,
        })

        settingsModal
          .getChild('settingsBridgeComponent')
          .addClass('vjs-subtitle-setting-bridge-component')

        settingsModal.addClass('vjs-subtitle-settings-modal')

        const Button = videojs.getComponent('Button')

        // Setting SVG -----
        let settingsModalOpen = false

        const toggleSubtitleSettingsModalButton = new Button(player, {
          className: 'vjs-show-subtitle-modal-button',
          clickHandler() {
            if (settingsModalOpen) {
              videojs.log('player closing modal')
              settingsModal.close()
              settingsModalOpen = false
            } else {
              videojs.log('player opening modal')
              settingsModal.open()
              settingsModalOpen = true
              player.controls(true)
            }
          },
        })


        // Close modal on click outside
        const closeModalOnClickOutside = event => {
          // Ensure modal is open and the click is outside the modal
          videojs.log('film stopped')
          if (event.target.id === 'vjs_video_3_html5_api') {
            settingsModal.close()
            settingsModalOpen = false
            videojs.log('player closed')
          }
        }

        const buttonSetting = toggleSubtitleSettingsModalButton.el()

        const SettingSvgContainer = document.createElement('div')

        ReactDOM.render(
          <Icon
            glyph={SettingsSvg}
            style={{ cursor: 'pointer' }}
            width={20}
            height={20}
            fill="white"
          />,
          SettingSvgContainer,
        )
        buttonSetting.appendChild(SettingSvgContainer)
        // ---------

        player.on('click', closeModalOnClickOutside)

        player.on('ready', () => {
          videojs.log('player on ready')
          player.addChild(settingsModal)
          const controlBar = player.getChild('ControlBar')

          // Poster covering the div
          const poster = player.posterImage.el() // Get the poster div element
          const picture = poster.querySelector('picture') // Get the picture element
          let img


          // Directly apply styles to the poster, picture, and img elements
          poster.style.width = '100%'
          poster.style.height = '100%'
          poster.style.objectFit = 'cover'
          poster.style.position = 'absolute'


          if (picture) {
            img = picture.querySelector('img') // Get the img element

            picture.style.width = '100%'
            picture.style.height = '100%'
            picture.style.objectFit = 'cover'
            picture.style.position = 'absolute'
          }

          if (img) {
            img.style.width = '100%'
            img.style.height = '100%'
            img.style.objectFit = 'cover'
            img.style.position = 'absolute'
          }
          videojs.log('image set')

          controlBar.addChild('playToggle')
          controlBar.addChild('volumePanel')
          controlBar.addChild('progressControl')
          ChapterMarkersProgressBarControl.updateMarkers(player)

          videojs.log('update markers set')
          controlBar.addChild('remainingTimeDisplay')

          controlBar.addChild(toggleSubtitleSettingsModalButton)
          videojs.log('subtitle settings modal button set')
          controlBar.addChild('fullscreenToggle')
        })

        player.on('play', () => {
          videojs.log('film started')
          ChapterMarkersProgressBarControl.updateMarkers(player)
          videojs.log('film started and markers updated')
        })

        player.on('timeupdate', () => {
          const currentTime = player.currentTime()
          const duration = player.duration()

          const played = currentTime / duration

          const playedTime = currentTime.toFixed(2)

          if (playedTime !== latestTime) {
            if (onProgress) {
              onProgress({ played })
            }

            latestTime = playedTime
          }
        })
      })

      return undefined
    }

    const player = playerRef.current
    player.textTrackSettings.setValues(options.videoPlayerPreferences)
    player.playbackRate(options.videoPlayerPreferences.speed)
    if (!player) {
      return undefined
    }

    // Update the player with props changes

    player.autoplay(options.autoplay)
    player.src(options.sources)

    let outdated = false

    player.ready(() => {
      if (outdated) {
        return
      }

      // remove any tracks that no longer exist, and add any new tracks
      // noinspection JSValidateTypes
      /**
       * @type {import('video.js').default.TextTrack[]}
       */
      const remoteTracks = Array.from(player.remoteTextTracks())

      /**
       * @param {import('video.js').default.TextTrack} remoteTrack
       * @param {import('video.js').default.TextTrackOptions} track
       * @return {boolean}
       */
      const isSameTrack = (remoteTrack, track) =>
        remoteTrack.src === track.src &&
        remoteTrack.kind === track.kind &&
        remoteTrack.label === (track.label ?? '') &&
        remoteTrack.language === track.srclang &&
        remoteTrack.default === track.default

      const newTracks = options.tracks.filter(track => {
        const index = remoteTracks.findIndex(remoteTrack =>
          isSameTrack(remoteTrack, track),
        )

        return index === -1
      })

      const tracksToRemove = remoteTracks.filter(remoteTrack => {
        const index = options.tracks.findIndex(track =>
          isSameTrack(remoteTrack, track),
        )

        return index === -1
      })

      const tracksModified = tracksToRemove.length > 0 || newTracks.length > 0

      if (tracksModified) {
        tracksToRemove.forEach(track => {
          // noinspection JSCheckFunctionSignatures
          player.removeRemoteTextTrack(track)
        })

        newTracks.forEach(track => {
          // noinspection JSCheckFunctionSignatures
          player.addRemoteTextTrack(track)
        })

        setTimeout(() => {
          player.ready(() => {
            ChapterMarkersProgressBarControl.updateMarkers(player)
          })
        }, 20)
      }
    })

    return () => {
      outdated = true
    }
  }, [options])

  // Dispose the Video.js player when the functional component unmounts
  useLayoutEffect(() => {
    const player = playerRef.current

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose()
        playerRef.current = null
      }
    }
  }, [playerRef])

  return (
    <div data-vjs-player {...containerProps}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video ref={videoRef} className="video-js" {...videoProps} />
    </div>
  )
}

// noinspection JSValidateTypes
VideoJSPlayer.propTypes = {
  /**
   * @type {React.HTMLAttributes<HTMLDivElement>}
   */
  containerProps: PropTypes.object,
  /**
   * @type {import('@types/video.js').VideoJsPlayerOptions}
   */
  options: PropTypes.object,
  /**
   * @type {React.VideoHTMLAttributes}
   */
  videoProps: PropTypes.object,
  /**
   * @type {({played: number}) => void}
   */
  onProgress: PropTypes.func,
  /**
   * @type {(player: import('@types/video.js').VideoJsPlayer) => void}
   */
  onReady: PropTypes.func,
  updateUserPreferences: PropTypes.func,
}

VideoJSPlayer.defaultProps = {
  onReady: null,
  onProgress: null,
  options: {},
  videoProps: {},
  containerProps: {},
  updateUserPreferences: {},
}
