import {
  googleAuthClientId,
  microsoftAuthClientId,
  myloginAuthClientId,
} from '@lyfta/components-config'
import {
  clearErrors,
  oAuthSignIn,
  oAuthSignInMicrosoft,
  oAuthSignInMylogin,
  signIn,
} from '@lyfta/components-data/src/Store/Actions/auth'
import { getLocaleSetting } from '@lyfta/components-data/src/Store/Selectors/settings'
import { getIsAuthenticated } from '@lyfta/components-data/src/Store/Selectors/viewer'
import { I18n, getTranslation } from '@lyfta/components-i18n'
import { GoogleLogin } from '@react-oauth/google'
import React, { useCallback, useEffect } from 'react'
import MicrosoftLogin from 'react-microsoft-login'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import Wonde from '../../../Assets/Svg/Wonde'
import { Flex, Header, Icon, Separator } from '../../../Components'
import { PropTypes } from '../../../Helpers/prop-types'
import { signUpApp } from '../../../Services'
import { Link, LinkMylogin, Links, Panel, SignUpButton } from '../styles'
import SignInForm from './Form'

export const SignInScreen = ({
  initialValues,
  withGoogleLogin,
  withMicrosoftLogin,
  redirectPath,
  signInAction,
  signUp,
  oAuthSignInAction,
  paths,
  withHeader,
  withLinks,
  withMylogin,
  customButton,
  customLink,
  allowUsername,
  backgroundColorLogin,
  fontColorLink,
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(getIsAuthenticated)
  const locale = useSelector(getLocaleSetting)
  const { search } = useLocation()
  const params = window?.location
    ? new URLSearchParams(search)
    : {
        get() {
          return ''
        },
      }

  const myLoginCode = params.get('code')

  // Getting the url to use in the registration app and redirect the user back to the teacher app
  const path = window.location.pathname.slice(1)

  useEffect(() => {
    if (myLoginCode) {
      dispatch(oAuthSignInMylogin(myLoginCode))
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated && redirectPath) history.push(redirectPath)
  }, [isAuthenticated])

  // Cleaning error when user refresh the page.
  useEffect(() => {
    window.onbeforeunload = function () {
      dispatch(clearErrors())
    }
  })

  const MicrosoftDiv = () => {
    const authHandler = (err, data) => {
      // if (err) {
      //   console.error(err);
      // } else {
      //   dispatch(oAuthSignInMicrosoft(data, 'microsoft'))
      // }
      dispatch(oAuthSignInMicrosoft(data, 'microsoft'))
    }

    return (
      <div>
        <MicrosoftLogin
          clientId={microsoftAuthClientId}
          authCallback={authHandler}
        />
      </div>
    )
  }

  const responseGoogle = response => {
    if (!response) {
      return null
    }

    const { credential: idToken } = response

    return dispatch(oAuthSignInAction(idToken, 'google'))
  }

  const performPasswordLogin = useCallback(
    fields => {
      if (allowUsername) {
        const { username, password } = fields
        dispatch(signInAction(username, password))
      } else {
        const { email, password } = fields
        dispatch(signInAction(email, password))
      }
    },
    [allowUsername],
  )

  return (
    <Panel>
      {withHeader && <I18n tag={Header} text="auth.login" />}
      <SignInForm
        allowUsername={allowUsername}
        backgroundColorLogin={backgroundColorLogin}
        customButton={customButton}
        initialValues={initialValues}
        onSubmit={performPasswordLogin}
      />
      {withLinks && (
        <>
          <Links>
            <I18n
              fontColor={fontColorLink}
              href={paths.forgot}
              id="link-take-to-reset-password"
              tag={Link}
              text="auth.forgotPassword"
              to={paths.forgot}
            />
          </Links>
        </>
      )}
      <Separator label={getTranslation('auth.separatorLabel')} />

      {withGoogleLogin && googleAuthClientId && (
        <Flex alignItems="center" justifyContent="center">
          <GoogleLogin
            // onError={}
            width="215px"
            onSuccess={responseGoogle}
            locale={locale}
            size="large"
            // theme="filled_blue"
          />
        </Flex>
      )}
      {withMicrosoftLogin && microsoftAuthClientId && (
        <>
          <Flex alignItems="center" mt={3} justifyContent="center">
            <MicrosoftDiv width="100%" />
          </Flex>
        </>
      )}
      {withMylogin && myloginAuthClientId && (
        <>
          <Links pt={2}>
            <LinkMylogin
              href={paths.myLogin}
              textDecoration="none"
              target="_blank"
            >
              <Flex
                alignItems="center"
                width="215px"
                textDecoration="none"
                justifyContent="center"
                border="1px solid gray"
                padding="8px"
                backgroundColor="white"
              >
                <Icon
                  glyph={Wonde}
                  // fill="black"
                  width="24px"
                  height="24px"
                  mr={2}
                />
                <I18n i18nKey="auth.wondeLogIn" />
              </Flex>
            </LinkMylogin>
          </Links>
        </>
      )}
      {signUp && locale === 'en' && (
        <>
          <Separator label={getTranslation('auth.separatorLabel')} />
          <I18n
            id="btn-sign-up"
            tag={SignUpButton}
            text="auth.createAccount"
            onClick={signUpApp(encodeURIComponent(path))}
          />
        </>
      )}
      {customLink && (
        <>
          <Separator label={getTranslation('auth.separatorLabel')} />
          {customLink}
        </>
      )}
    </Panel>
  )
}

SignInScreen.defaultProps = {
  allowUsername: false,
  backgroundColorLogin: false,
  customButton: false,
  customLink: false,
  fontColorLink: '',
  initialValues: {},
  paths: null,
  redirectPath: null,
  signInAction: signIn,
  signUp: false,
  oAuthSignInAction: oAuthSignIn,
  withGoogleLogin: false,
  withMicrosoftLogin: false,
  withMylogin: false,
  withHeader: false,
  withLinks: false,
}

SignInScreen.propTypes = {
  allowUsername: PropTypes.bool,
  backgroundColorLogin: PropTypes.string,
  customButton: PropTypes.func,
  customLink: PropTypes.func,
  fontColorLink: PropTypes.string,
  initialValues: PropTypes.object,
  oAuthSignInAction: PropTypes.func,
  paths: PropTypes.object,
  redirectPath: PropTypes.string,
  signInAction: PropTypes.func,
  signUp: PropTypes.bool,
  withGoogleLogin: PropTypes.bool,
  withHeader: PropTypes.bool,
  withLinks: PropTypes.bool,
  withMicrosoftLogin: PropTypes.bool,
  withMylogin: PropTypes.bool,
}
