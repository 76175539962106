import { getViewerType } from '@lyfta/components-data/src/Store/Selectors/viewer'
import { USER_TYPES } from '@lyfta/components-ui'
import { map } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'

import {
  getActivities,
  getIsTeachingInFrontOfClass,
} from '../../../../Store/Selectors/questTracker'
import Activity from '../Activity'
import TopPanel from '../TopPanel'
import { ActivityList, Container } from './styles'

const DELAY_MASCOT_CHANGE = 400
const DURATION_MASCOT_CHANGE = 300

const Activities = ({ isDrawerOpen, questAnimation }) => {
  const activities = useSelector(getActivities)
  const viewerType = useSelector(getViewerType)
  const isTeachingInFrontOfClass = useSelector(getIsTeachingInFrontOfClass)
  const questTitle = activities[0]?.name

  return (
    <Container animation={questAnimation} id="div-activities-menu">
      <TopPanel
        questTitle={questTitle}
        delayMascotChange={DELAY_MASCOT_CHANGE}
        durationMascotChange={DURATION_MASCOT_CHANGE}
      />
      <ActivityList id="div-activities-list">
        {map(activities, (a, index) => {
          const key = `activity-${a.id}`
          let canBeSelected = true
          if (viewerType === USER_TYPES.students || isTeachingInFrontOfClass) {
            if (index > 1) {
              const previousActivity = activities[index - 1]
              canBeSelected = previousActivity.complete
            }
          }
          return (
            <Activity
              activity={a}
              animationDelay={DELAY_MASCOT_CHANGE + DURATION_MASCOT_CHANGE}
              canBeSelected={canBeSelected}
              index={index}
              isDrawerOpen={isDrawerOpen}
              key={key}
            />
          )
        })}
      </ActivityList>
    </Container>
  )
}
Activities.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  questAnimation: PropTypes.object.isRequired,
}
export default Activities
