import { css, styled, themeGet } from '@lyfta/components-theme'
import { Arrow, Box, Flex } from '@lyfta/components-ui'

export const Container = styled(Flex).attrs(() => ({}))`
  min-height: 100%;
  overflow: auto;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
  background: ${themeGet('colors.neutral_100')};
`
export const LeftBlock = styled(Flex).attrs(() => ({ pl: 4, pb: 6 }))`
  width: 40%;
  height: 100%;
  max-width: 500px;
  min-width: 300px;
  flex-direction: column;
  background-color: ${themeGet('colors.neutral_100')};
`

export const RightBlock = styled(Flex).attrs(() => ({ px: 3, pb: 2 }))`
  background-color: ${themeGet('colors.neutral_200')};

  flex-direction: row;
  width: 75%;
  height: 100%;
  flex-wrap: wrap;
  overflow: auto;
  border-left: 1px solid ${themeGet('colors.neutral_400')};
`
export const CardsContainer = styled(Flex).attrs(() => ({ p: 1 }))`
  flex-direction: row;
  min-height: 100%;
  width: 100%;
  flex-wrap: wrap;
  gap: 35px;
`
export const Title = styled(Box).attrs(() => ({}))`
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 28px;
  color: ${themeGet('colors.black_800')};
`
export const LanguageContainer = styled(Flex)`
  flex-direction: row;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: ${themeGet('colors.doveGray')};
  margin-bottom: 20px;
`
export const ArrowWithMargin = styled(Arrow)`
  margin-left: 5px;
`
export const SubscribeContainer = styled(Flex)`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: ${p =>
    p.color
      ? themeGet(`colors.${p.color}`)(p)
      : themeGet('colors.secondary_130')};
`
export const AuthorContainer = styled(Flex)`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: ${themeGet('colors.black_600')};
`
export const FileContainer = styled(Flex)`
  width: 100%;
  max-width: 300px;
  flex-direction: column;
`
export const ImageWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  margin: 0 16px;
  border-radius: 10px;
`
export const MenuItem = styled(Flex).attrs({})`
  justify-content: left;
  align-items: center;
  width: 100%;
  font-weight: 700;
  line-height: 18px;

  ${props =>
    !props.selected &&
    css`
      color: ${themeGet('colors.silver')};
    `}

  &:hover {
    cursor: pointer;
  }
`

export const ThumbImage = styled('img')`
  max-height: 196px;
  max-width: 294px;
  border-radius: 10px;
`

export const MenuText = styled(Flex).attrs({ py: 4, pr: 4 })`
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  color: ${themeGet('colors.secondary_100')};
`
export const MenuTextBack = styled(Flex).attrs({})`
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  justify-content: flex-start;
  align-items: center;
  color: ${themeGet('colors.black_900')};
`
export const TitleViewOnly = styled(Flex).attrs({ mb: 1 })`
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.5px;
  color: ${themeGet('colors.black_800')};
`
export const TextColor = styled(Flex).attrs({})`
  color: ${themeGet('colors.black_700')};
`
