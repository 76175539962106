import { jsx } from '@emotion/react'
/** @jsx jsx */
import { styled, themeGet } from '@lyfta/components-theme'
import shadows from '@lyfta/components-theme/src/themes/Main/shadows'
import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import Select, { components, mergeStyles } from 'react-select'

import { ChevronDown, ChevronUp, Tick } from '../../Assets/Icons'
import { RadioSelected, RadioUnselected } from '../../Assets/Svg'
import { Box } from '../Box'
import { Flex } from '../Flex'

const optionDefaultPaddingX = 12

export const Wrapper = styled(Box)``

const CustomDropdownIndicator = props => {
  const { selectProps } = props
  return (
    <components.DropdownIndicator {...props}>
      {selectProps.menuIsOpen ? <ChevronUp /> : <ChevronDown />}
    </components.DropdownIndicator>
  )
}

CustomDropdownIndicator.propTypes = {
  selectProps: PropTypes.object.isRequired,
}

const CustomOptionLabelContainer = styled(Box)`
  margin-right: 16px;
`

const CustomOptionIconContainer = styled(Box)`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: end;
`

const CustomOptionIconPlaceholder = styled(Box)`
  height: 24px;
  width: 24px;
`

const CustomOption = props => {
  const { children, isSelected, selectProps, value } = props
  const ref = useRef()
  useEffect(() => {
    if (isSelected) {
      ref.current.scrollIntoView()
    }
  }, [isSelected])

  const RadioIcon = () => {
    if (!selectProps.allProps.showRadioIcon) {
      return null
    }

    if (isSelected) {
      return <RadioSelected width="16px" height="16px" />
    }

    return <RadioUnselected width="16px" height="16px" />
  }

  const TickOrPlaceholder = () => {
    if (selectProps.allProps.showRadioIcon) {
      return null
    }

    if (isSelected) {
      return (
        <Tick
          css={{
            '& > path': {
              fill: themeGet('colors.black_600')(selectProps.allProps),
            },
          }}
          id="dropdown-option-selected-tick"
        />
      )
    }

    return (
      <CustomOptionIconPlaceholder className="react-select-dropdown-option-icon-placeholder" />
    )
  }

  return (
    <components.Option {...props} innerRef={ref}>
      <Flex mr={1}>
        <RadioIcon />
      </Flex>

      <CustomOptionLabelContainer
        className="react-select-dropdown-option-label-container"
        id={`dropdown-option-label-container-${value}`}
      >
        {children}
      </CustomOptionLabelContainer>
      <CustomOptionIconContainer className="react-select-dropdown-option-icon-container">
        <TickOrPlaceholder />
      </CustomOptionIconContainer>
    </components.Option>
  )
}

CustomOption.defaultProps = {
  value: '',
  showRadioIcon: false,
}

CustomOption.propTypes = {
  children: PropTypes.node.isRequired,
  isSelected: PropTypes.bool.isRequired,
  showRadioIcon: PropTypes.bool,
  selectProps: PropTypes.object.isRequired,
  value: PropTypes.string,
}

const CustomSingleValue = props => {
  const { children, selectProps } = props
  return (
    <components.SingleValue {...props}>
      {selectProps.value.selectedLabel
        ? selectProps.value.selectedLabel
        : children}
    </components.SingleValue>
  )
}

CustomSingleValue.propTypes = {
  children: PropTypes.node.isRequired,
  selectProps: PropTypes.object.isRequired,
}

const stylesFunction = props => {
  const { extendStyles } = props

  const stylesObject = {
    control: baseStyles => ({
      ...baseStyles,
      backgroundColor: 'unset',
      border: 'none',
      boxShadow: 'none',
      minHeight: 'unset',
      cursor: 'pointer',
    }),
    dropdownIndicator: baseStyles => ({
      ...baseStyles,
      padding: '0',
      '& svg > path': themeGet('colors.black_600')(props),
    }),
    indicatorSeparator: baseStyles => ({
      ...baseStyles,
      display: 'none',
    }),
    menu: baseStyles => ({
      ...baseStyles,
      borderRadius: (() => {
        if (props.menuBorderRadius === 'bottom') {
          return '0 0 5px 5px'
        }
        if (props.menuBorderRadius === 'top') {
          return '5px 5px 0 0'
        }
        return 'unset'
      })(),
      boxShadow: shadows.lifted,
      left: `-${optionDefaultPaddingX}px`,
      overflow: 'hidden',
      width: 'max-content',
    }),
    menuList: baseStyles => ({
      ...baseStyles,
      padding: '0',
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      alignItems: 'center',
      backgroundColor: (() => {
        if (state.isSelected) {
          return themeGet('colors.black_200')(props)
        }
        if (state.isFocused) {
          return themeGet('colors.primary_60_50')(props)
        }
        return themeGet('colors.neutral_100')(props)
      })(),
      color: themeGet('colors.black_700')(props),
      display: 'flex',
      fontWeight: '400',
      padding: `8px ${optionDefaultPaddingX}px`,
      '&:hover': {
        backgroundColor: state.isSelected
          ? themeGet('colors.black_200')(props)
          : themeGet('colors.primary_60_50')(props),
      },
    }),
    singleValue: baseStyles => ({
      ...baseStyles,
      color: themeGet('colors.black_600')(props),
      fontSize: '16px', // Body 1
      fontWeight: '400', // Body 1
      letterSpacing: '0.5px', // Body 1
      lineHeight: '24px', // Body 1
      margin: '0',
    }),
    valueContainer: baseStyles => ({
      ...baseStyles,
      padding: '0',
    }),
  }

  return mergeStyles(stylesObject, extendStyles)
}

export const StyledReactSelect = styled(Select).attrs(props => ({
  allProps: props,
  components: {
    DropdownIndicator: CustomDropdownIndicator,
    Option: CustomOption,
    SingleValue: CustomSingleValue,
    ...props.extendComponents,
  },
  styles: stylesFunction(props),
  menuPlacement: props.menuPlacement ? props.menuPlacement : 'top', // Added this line
}))``
