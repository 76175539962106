import { isTeacherApp } from '@lyfta/components-config'
import {
  I18n,
  getTranslatedField,
  getTranslation,
} from '@lyfta/components-i18n'
import {
  Arrow,
  ButtonNew,
  Constraints,
  Flex,
  Icon,
  LyftaForm,
  Modal,
  Plus,
  ShareLink,
  TopBar,
  UnlockModal,
} from '@lyfta/components-ui'
import { isEmpty, map, noop, range } from 'lodash'
import get from 'lodash/get'
import { nanoid } from 'nanoid'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { IntercomAPI } from 'react-intercom'
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  addContentToCollection,
  loadCollection,
  removeContentFromCollection,
  requestSaveCollection,
  requestUpdateCollection,
} from '../../Store/Actions/collections'
import { clearSearchEditors } from '../../Store/Actions/search'
import {
  getCollectionName,
  getCurrentCollection,
  getIsLoading,
} from '../../Store/Selectors/collections'
import Card from '../Card'
import myForm from './form'
import { generateInitialValues } from './form/initialValues'
import {
  CardsContainer,
  Container,
  LeftBlock,
  MenuItem,
  MenuTextBack,
  RightBlock,
  Title,
} from './styles'

const CollectionEditor = ({
  SearchComponent,
  collectionId,
  editorPath,
  viewOnly,
  paths,
}) => {
  const dispatch = useDispatch()

  const collection = useSelector(getCurrentCollection)
  const isLoading = useSelector(getIsLoading)
  const [showLyftaContentModal, setShowLyftaContentModal] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false)

  const [showUnlockModal, setShowUnlockModal] = useState(false)
  const [itemSelected, setItemSelected] = useState(null)

  const history = useHistory()

  useEffect(() => {
    if (collectionId !== 'new') {
      dispatch(loadCollection(collectionId))
    } else {
      new Promise(resolve => dispatch(requestSaveCollection({}, resolve))).then(
        value => {
          history.push(editorPath(value))
        },
      )
    }
  }, [collectionId])

  const handleIntercomModal = ok => {
    if (ok) {
      const itemName = getTranslatedField(itemSelected, 'name')
      const userMessageToIntercom =
        getTranslation('user.userMessageToIntercom') + itemName

      IntercomAPI('showNewMessage', userMessageToIntercom)
    }
    setShowUnlockModal(false)
  }

  const handleOpenPreview = item => {
    if (isTeacherApp) {
      const isLicensed = get(item, 'isLicensed')
      if (isLicensed) {
        if (item.type === 'lesson_plan_template') {
          history.push(
            paths.searchNew.lessonPlanPreview(
              item.lessonPlanTemplate.id,
              item.type,
            ),
          )
        } else {
          history.push(paths.searchNew.resourcePreview(item.id, item.type))
        }
      } else {
        setItemSelected(item)
        setShowUnlockModal(true)
      }
      return
    }
    // Admin app
    if (item.type === 'lesson_plan_template') {
      history.push(paths.resource.lessonPlanPreview(item.id))
      return
    }
    history.push(paths.resource.resourcePreview(item.id))
  }

  const handleRemoveContentFromCollection = content => {
    dispatch(
      removeContentFromCollection({
        collection,
        content,
        customMessage: { success: { message: 'toast.success.contentRemoved' } },
      }),
    )
  }

  const handleSelectLyftaContent = item => {
    const collectionContent = [
      {
        id: collection.id,
        type: item.type,
        contentId: item.id,
      },
    ]
    dispatch(
      addContentToCollection({
        collectionContent,
        customMessage: { success: { message: 'toast.success.contentAdded' } },
      }),
    )
    setShowLyftaContentModal(false)
  }

  const handleShareModal = () => setShowShareModal(true)

  // Topbar
  const renderLeftContainer = () => {
    return <I18n i18nKey="collections.title" tag={Title} />
  }
  const renderRightContainer = () => {
    if (viewOnly) return <></>
    return (
      <ButtonNew
        leadingIcon={<Icon glyph={Plus} height={20} mr={2} width={20} />}
        type="link"
        onClick={() => {
          setShowLyftaContentModal(true)
          dispatch(clearSearchEditors())
        }}
      >
        <I18n i18nKey="actions.addMore" />
      </ButtonNew>
    )
  }
  // End Topbar

  const loadingCards = map(range(1, 3), () => (
    <Card controls height="430px" isLoading key={nanoid()} width="300px" />
  ))

  return (
    <Container>
      <LeftBlock>
        {isLoading && (
          <>
            <Skeleton
              width={300}
              height={200}
              style={{
                borderRadiusTop: '8px',
                marginTop: '40px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            />
            <Flex width="100%" p={1} mb={3}>
              <Skeleton
                width={40}
                height={40}
                style={{
                  borderRadius: '50%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />
              <Skeleton
                width={40}
                height={40}
                style={{
                  borderRadius: '50%',
                  marginLeft: '5px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />
              <Skeleton
                width={40}
                height={40}
                style={{
                  borderRadius: '50%',
                  marginLeft: '5px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />
            </Flex>
            <Skeleton
              width={300}
              height={30}
              style={{
                lineHeight: 4,
                borderRadiusTop: '8px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            />
            <Skeleton
              width={200}
              height={25}
              style={{
                lineHeight: 24,
                borderRadiusTop: '8px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            />
          </>
        )}
        {!isLoading && (
          <>
            <MenuItem mt={2} onClick={() => history.goBack()}>
              <Arrow
                color="black_900"
                direction="left"
                width={8}
                height={8}
                mr={2}
              />
              <MenuTextBack>
                <I18n i18nKey="actions.back" mr={4} />
              </MenuTextBack>
            </MenuItem>

            <LyftaForm
              createInitialValues={generateInitialValues}
              excludeSave
              withoutHeader={!!isTeacherApp}
              constraints={{
                ...Constraints.nameConstraint,
                ...Constraints.descriptionConstraint,
              }}
              actionsForm={{
                handleShareModal,
              }}
              fields={formRendererProps => myForm(formRendererProps)}
              includeAutoSave
              inlineErrorMessages
              modelName="collections"
              recordId={collection.id}
              recordNameSelector={getCollectionName}
              recordSelector={getCurrentCollection}
              updateAction={requestUpdateCollection}
              wrapperProps={{ m: 0, pr: 3 }}
              viewOnly={viewOnly}
            />
          </>
        )}
      </LeftBlock>

      <RightBlock>
        {isLoading && <Flex mt={4}>{loadingCards}</Flex>}

        {!isLoading && (
          <>
            <Flex width="100%" p={1} mb={3}>
              <TopBar
                width="100%"
                height="40px"
                mx={0}
                mt={0}
                leftContainer={renderLeftContainer()}
                rightContainer={renderRightContainer()}
              />
            </Flex>
            <CardsContainer flexWrap="wrap" gap="35px" width="100%">
              {map(collection.contents, (item, index) => {
                return (
                  <Card
                    controls
                    favorited={!isEmpty(item.favorites)}
                    id={item.id}
                    index={index}
                    item={item}
                    key={item.id}
                    deleteIconAction={() =>
                      handleRemoveContentFromCollection(item)
                    }
                    onView={() => handleOpenPreview(item)}
                  />
                )
              })}
            </CardsContainer>
          </>
        )}
      </RightBlock>

      <Modal
        isOpen={showLyftaContentModal}
        minHeight="90vh"
        minWidth="90%"
        showFooter={false}
        onCallback={() => setShowLyftaContentModal(false)}
        modalStyle={{
          wrapperContainer: {
            width: '100%',
          },
          contentContainer: {
            specialOverflowSettings: true,
          },
        }}
      >
        <SearchComponent
          isCollectionEditor
          lyftaContent
          onSelect={handleSelectLyftaContent}
        />
      </Modal>

      <ShareLink
        isOpen={showShareModal}
        onChooseNegative={() => setShowShareModal(false)}
        modalStyle={{
          wrapperContainer: {
            maxWidth: '100%',
          },
        }}
      />
      {showUnlockModal && (
        <UnlockModal
          isOpen={showUnlockModal}
          onCallback={handleIntercomModal}
        />
      )}
    </Container>
  )
}

CollectionEditor.defaultProps = {
  viewOnly: false,
  editorPath: noop,
}

CollectionEditor.propTypes = {
  collectionId: PropTypes.string.isRequired,
  viewOnly: PropTypes.bool,
  paths: PropTypes.object.isRequired,
  editorPath: PropTypes.func,
  SearchComponent: PropTypes.element.isRequired,
}

export default CollectionEditor
