/* eslint-disable */
import { I18n, getTranslation } from '@lyfta/components-i18n'
import {
  AnimatedInputNew,
  AnimatedTextAreaNew,
  ButtonNew,
  Flex,
  Graph,
  Icon,
  Notification,
  NotificationPlus,
  Share,
} from '@lyfta/components-ui'
import { get } from 'lodash'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  addToFavorites,
  tryRemoveFromFavorites,
} from '../../../Store/Actions/favorites'
import {
  getContentFavoriteState,
  getContentId,
} from '../../../Store/Selectors/contents'
import {
  AuthorContainer,
  FileContainer,
  ImageWrapper,
  SubscribeContainer,
  TextColor,
  ThumbImage,
  TitleViewOnly,
} from '../styles'

const myForm = props => {
  const {
    dirty,
    handleSubmit,
    values,
    viewOnly,
    actionsForm: { handleShareModal },
  } = props

  const getFavorite = useSelector(getContentFavoriteState)
  const contentId = useSelector(getContentId)
  const dispatch = useDispatch()
  const thumbUrl = get(values, 'posterUrl')
  const submit = () => {
    if (dirty) {
      handleSubmit()
    }
  }

  const onClickFavorite = useCallback(
    e => {
      e.stopPropagation()
      if (getFavorite) {
        return new Promise(resolve =>
          dispatch(
            tryRemoveFromFavorites(
              { favorite: getFavorite, contentId },
              resolve,
              { success: { message: 'toast.success.unsubscribed' } },
            ),
          ),
        ).then(() => {})
      }

      return dispatch(
        addToFavorites(contentId, {
          success: { message: 'toast.success.subscribed' },
        }),
      )
    },
    [getFavorite],
  )

  return (
    <form onBlur={submit} onSubmit={submit}>
      <Flex alignItems={viewOnly ? 'flex-start' : 'flex-end'} flexWrap="wrap">
        <Flex my={4} flexWrap="wrap">
          <FileContainer mr={2} mb={2}>
            {thumbUrl ? (
              <ImageWrapper>
                <ThumbImage src={thumbUrl} />
              </ImageWrapper>
            ) : null}
          </FileContainer>
          {viewOnly && (
            <Flex justifyContent="left" alignItems="flex-start" flexWrap="wrap">
              <ButtonNew
                id="btn-subscribe"
                buttonDiv
                leadingIcon={
                  <Icon
                    glyph={getFavorite ? Notification : NotificationPlus}
                    height={getFavorite ? '20px' : '25px'}
                    width={getFavorite ? '20px' : '25px'}
                  />
                }
                type="icon"
                mr={2}
                secondary={getFavorite ? false : true}
                onClick={onClickFavorite}
              />

              {/* <ButtonNew
                id="btn-duplicate"
                leadingIcon={<Icon glyph={Duplicate} height={20} width={20} />}
                type="icon"
                mr={1}
                secondary

                // onClick={disableNewRoute ? handleOpenTeachersLimitModal : openModal}
              /> */}
              <ButtonNew
                id="btn-share"
                leadingIcon={<Icon glyph={Share} height={20} width={20} />}
                type="icon"
                mr={2}
                secondary
                buttonDiv
                onClick={() => handleShareModal()}
              />
            </Flex>
          )}
        </Flex>
      </Flex>

      <AnimatedInputNew
        data-intercom-target="first-name"
        id="input-first-name"
        label={<I18n text="fields.collectionName" />}
        mb={0}
        name="name"
        description={getTranslation('collections.nameText')}
        viewOnly={viewOnly}
        viewOnlyProps={{
          hideLabel: true,
          fieldTag: TitleViewOnly,
        }}
      />

      {viewOnly && (
        <>
          <Flex>
            <AuthorContainer>
              <I18n i18nKey="collections.createdByLyfta" />{' '}
            </AuthorContainer>
          </Flex>
          <Flex my={2}>
            <SubscribeContainer>
              <Icon
                glyph={Notification}
                width={16}
                height={16}
                mr={2}
                fill="secondary_130"
              />
              <I18n
                i18nKey="collections.subscribedCount"
                options={{ count: values.subscribedCount || 0 }}
              />
            </SubscribeContainer>
          </Flex>
          <Flex mb={2}>
            <SubscribeContainer color="secondary_60">
              <Icon
                glyph={Graph}
                width={16}
                height={16}
                mr={2}
                fill="secondary_60"
              />
              <I18n
                i18nKey="collections.viewedCount"
                options={{ count: values.viewedCount || 0 }}
              />
            </SubscribeContainer>
          </Flex>
        </>
      )}

      <AnimatedTextAreaNew
        allowEmptyField={false}
        description={getTranslation('collections.descriptionText')}
        inputId="text-area-activity-description"
        rows={3}
        label={<I18n i18nKey="fields.collectionDescription" />}
        mb={4}
        name="description"
        viewOnly={viewOnly}
        viewOnlyProps={{ fieldTag: TextColor }}
      />

      <AnimatedTextAreaNew
        description={getTranslation('collections.teacherNotesText')}
        rows={3}
        label={<I18n i18nKey="collections.teacherNotes" />}
        mb={3}
        name="teacherNotes"
        viewOnly={viewOnly}
        viewOnlyProps={{ fieldTag: TextColor }}
      />
      {viewOnly && (
        <AnimatedInputNew
          description={getTranslation('collections.subjects')}
          rows={3}
          label={<I18n i18nKey="collections.subjects" />}
          mb={3}
          name="subjects"
          viewOnly={viewOnly}
          viewOnlyProps={{ fieldTag: TextColor }}
        />
      )}
      {viewOnly && (
        <AnimatedInputNew
          description={getTranslation('collections.ageRanges')}
          rows={3}
          label={<I18n i18nKey="collections.ageRanges" />}
          mb={3}
          name="ageRanges"
          viewOnly={viewOnly}
          viewOnlyProps={{ fieldTag: TextColor }}
        />
      )}
      {viewOnly && (
        <AnimatedInputNew
          description={getTranslation('collections.tags')}
          rows={3}
          label={<I18n i18nKey="collections.tags" />}
          mb={3}
          name="tags"
          viewOnly={viewOnly}
          viewOnlyProps={{ fieldTag: TextColor }}
        />
      )}
      <AnimatedInputNew
        rows={3}
        label={<I18n i18nKey="collections.keywords" />}
        mb={3}
        name="keywords"
        description={getTranslation('collections.keywordsDescription')}
        viewOnly={viewOnly}
        viewOnlyProps={{ fieldTag: TextColor }}
      />
    </form>
  )
}

myForm.defaultProps = {
  handleSubmit: noop,
  viewOnly: false,
}
myForm.propTypes = {
  dirty: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func,
  viewOnly: PropTypes.bool,
}

export default myForm
