import {
  getTranslatedField,
  getTranslation,
  validLanguages,
} from '@lyfta/components-i18n'
import {
  capitalizeFirstLetter,
  createStringFromArray,
} from '@lyfta/components-ui/src/Services/Utils'
import { get, pick } from 'lodash'

export const generateInitialValues = record => {
  return {
    ...pick(record, [
      'id',
      ...validLanguages.map(l => `name${capitalizeFirstLetter(l)}`),
    ]),
    name:
      getTranslatedField(record, 'name') ||
      getTranslation('collections.newCollection'),
    description:
      getTranslatedField(record, 'description') ||
      getTranslation('collections.newCollectionDescription'),
    posterUrl: getTranslatedField(record, 'content.posterUrl'),
    teacherNotes: getTranslatedField(record, 'teacherNotes'),
    subscribedCount: get(record, 'subscribedCount') || '0',
    viewedCount: get(record, 'content.viewedCount') || '0',
    keywords: (get(record, `keywordsEn`) || []).join(', '),
    ageRanges: createStringFromArray(get(record, 'ageRanges'), 'name'),
    subjects: createStringFromArray(get(record, 'subjects'), 'name'),
    tags: createStringFromArray(get(record, 'content.tags'), 'nameEn'),
  }
}
