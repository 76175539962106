import { getLocaleSetting } from '@lyfta/components-data/src/Store/Selectors/settings'
import { getSubjects } from '@lyfta/components-data/src/Store/Selectors/subjects'
import { getViewer } from '@lyfta/components-data/src/Store/Selectors/viewer'
import {
  I18n,
  Trans,
  TransLink,
  getCurrentLanguage,
  getTranslatedField,
  getTranslation,
} from '@lyfta/components-i18n'
import {
  DataTable,
  Empty,
  Flex,
  UnlockModal,
  convertArrayToString,
  getFilteredItems,
  groupBy,
} from '@lyfta/components-ui'
import { isTrialing } from '@lyfta/components-ui/src/Services/Utils'
import { cloneDeep, filter, isEmpty, map, range } from 'lodash'
import get from 'lodash/get'
import { nanoid } from 'nanoid'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { IntercomAPI } from 'react-intercom'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import * as ContentTypes from '../../Constants/contents'
import { loadContents } from '../../Store/Actions/contents'
import {
  clearSearchEditors,
  toggleFilters,
  toggleNewSearchTagsAndKeywords,
  toggleSearchEditorsFilters,
  toggleSearchEditorsKeywords,
  toggleSearchEditorsSort,
  toggleSort,
} from '../../Store/Actions/search'
import {
  getContents,
  getIsLoading,
  getPaged,
} from '../../Store/Selectors/contents'
import {
  getFilters,
  getNewSearch,
  getSearchEditorsFilters,
  getSearchEditorsKeywords,
  getSearchEditorsSort,
  getSort,
} from '../../Store/Selectors/search'
import { getTagGroups } from '../../Store/Selectors/tagGroups'
import RibbonRow from '../Blocks/RibbonRow'
/* eslint-disable */
import Card from '../Card'
import Search from './search'
import {
  Container,
  ContainerUL,
  DescriptionRibbonRow,
  HelpLink,
  SearchResultsFlex,
  SearchResultsGrid,
  ShowAllRibbonRow,
  TitleRibbonRow,
  TransLinkContainer,
} from './styles'

const loadingCards = map(range(1, 10), () => (
  <Card controls height="430px" isLoading key={nanoid()} width="300px" />
))

const SearchPage = ({ onSelect, lyftaContent, isCollectionEditor, paths }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const isLessonPlandEditorOrCollectionEditor = () =>
    lyftaContent || isCollectionEditor

  const { licenseType } = useSelector(getViewer)
  const defaultSortSearch = isTrialing(licenseType)
    ? 'unlocked_content'
    : 'relevant'
  const locale = useSelector(getLocaleSetting)

  const filters = isLessonPlandEditorOrCollectionEditor()
    ? useSelector(getSearchEditorsFilters)
    : useSelector(getFilters)

  const sortSearch = isLessonPlandEditorOrCollectionEditor()
    ? useSelector(getSearchEditorsSort)
    : useSelector(getSort)

  const tagGroups = useSelector(getTagGroups)
  const subjects = useSelector(getSubjects)

  const loading = useSelector(getIsLoading)
  const newSearch = isLessonPlandEditorOrCollectionEditor()
    ? useSelector(getSearchEditorsKeywords)
    : useSelector(getNewSearch)
  const [tagsAndKeywords, setTagsAndKeywords] = useState(newSearch)
  const [showUnlockModal, setShowUnlockModal] = useState(false)
  const [itemSelected, setItemSelected] = useState(null)

  const searchParams = new URLSearchParams(location.search)

  const [filterObject, setFilterObject] = useState(filters)

  const [filtersParam, setFiltersParam] = useState(searchParams.get('filters'))
  const [query, setQuery] = useState(searchParams.get('query'))
  const [sortParam, setSortParam] = useState(searchParams.get('sortby'))

  const sortState = sortParam || sortSearch || defaultSortSearch
  const [sortBy, setSortBy] = useState(sortState)

  // Maps to convert from url params to filter options
  const mapDict = {
    ageRanges: ContentTypes.AGE_ID_MAP,
    lessonPlanTypes: ContentTypes.LESSON_PLAN_TYPE_MAP,
    filmDurations: ContentTypes.DURATION_ID_MAP,
    bbfcRatings: ContentTypes.BBFC_RATING_MAP,
    types: ContentTypes.CONTENT_TYPE_MAP,
  }

  const createObjects = str =>
    str.split(',').map(value => ({ id: nanoid(), type: 'query', value }))

  const addNewUrl = (fObject, qObject, sortString) => {
    let urlQuery = ''

    // Creating string for query
    if (qObject) urlQuery = map(qObject, k => k.value).join(',')

    // Creating string for filter
    let result = '{'
    let haveFilter = 0
    for (let key in fObject) {
      const item = fObject[key]
      const defaultFilter = item.default
      let itemValues = ''

      if (mapDict[key]) {
        //If filter default is true, not to add to the final string
        if (item.options[defaultFilter]) continue

        Object.entries(item.options).forEach(([k, value]) => {
          if (value) itemValues += `${mapDict[key][k]},`
        })
        if (itemValues) {
          result += `${key}=${itemValues}`

          result = result.slice(0, -1)
          result += ';'
          haveFilter = 1
        }

        continue
      }

      if (item.source === 'api' && item.options.length > 0) {
        itemValues = item.options.map(i => i)
        itemValues += ','
      } else {
        for (let option in item.options) {
          if (item.options[option]) itemValues += `${option},`
        }
      }

      if (itemValues) {
        result += `${key}=${itemValues}`
        haveFilter = 1
        result = result.slice(0, -1)
        result += ';'
      }
    }
    result = result.slice(0, -1)

    result += '}'

    const url = new URL(window.location.href) // Get current URL
    if (haveFilter) {
      url.searchParams.set('filters', result)
    } else {
      url.searchParams.delete('filters')
    } // Set 'filters' parameter
    if (urlQuery) {
      url.searchParams.set('query', urlQuery)
    } else {
      url.searchParams.delete('query')
    }

    if (sortString) {
      url.searchParams.set('sortby', sortString)
    } else {
      url.searchParams.delete('sortby')
    }

    window.history.pushState({}, '', url) // Change URL without reloading
    return url.href
  }

  const parseUrlString = urlString => {
    // Remove the curly brackets and split by semicolon
    const parts = urlString.slice(1, -1).split(';')
    let params = {}
    const allowedKeys = [
      'types',
      'ageRanges',
      'lessonPlanTypes',
      'bbfcRatings',
      'filmDurations',
      'subjects',
      'tags',
    ]
    const validOptions = {
      types: [
        'all_content',
        'story_world',
        'video',
        'article',
        'lesson_plan_template',
        '360_video',
        'series',
        'collection',
      ],
      // bbfcRatings: ['one', 'two', 'three'],
      // filmDurations: ['allLengths', 'under5', 'mins510', 'over10'],
      // 'subjects' and 'tags' are handled by the API, we assume any value here is acceptable, specific validation should be performed API-side
    }
    // Regex to detect prohibited keys, matching variations of '__proto__', 'constructor', 'prototype'
    const protoRegex = /__proto__|constructor|prototype/

    for (let part of parts) {
      let [key, value] = part.split('=')
      key = key.trim()
      // Check against prohibited key patterns and allow only specific allowed keys
      if (protoRegex.test(key) || !allowedKeys.includes(key)) {
        continue // Skip any keys that are prohibited or not explicitly allowed
      }

      value = value
        .split(',')
        .map(val => {
          if (
            key === 'subjects' ||
            key === 'tags' ||
            key === 'ageRanges' ||
            key === 'bbfcRatings' ||
            key === 'filmDurations' ||
            key === 'lessonPlanTypes'
          ) {
            // Only numbers admitted for 'subjects' and 'tags'
            return isNaN(val) ? null : Number(val)
          } else {
            // For other keys, ensure that the values are valid
            val = val.trim()
            return validOptions[key] && validOptions[key].includes(val)
              ? val
              : null
          }
        })
        .filter(val => val !== null) // Remove invalid or null values

      if (value.length > 0) {
        // Only add the key if there are valid values
        params[key] = value
      }
    }

    return params
  }

  const updateFiltersFromUrl = urlString => {
    // Creating filter object from URL
    let filtersUrl = parseUrlString(urlString)

    let newFilterState = cloneDeep(ContentTypes.initialStateFilter)

    // Update initalStateFilter according to filtersUrl obj
    for (let key in filtersUrl) {
      const defaultFilter = newFilterState[key].default

      if (mapDict[key]) {
        Object.entries(mapDict[key]).forEach(([k, value]) => {
          if (filtersUrl[key].includes(value)) {
            newFilterState[key].options[k] = true
            //ver default
            if (newFilterState[key].options[defaultFilter] === true)
              newFilterState[key].options[defaultFilter] = false
          }
        })
        continue
      }

      if (newFilterState[key]) {
        if (newFilterState[key].source === 'api') {
          for (let option in filtersUrl[key]) {
            if (!newFilterState[key].options.includes(filtersUrl[key][option]))
              newFilterState[key].options.push(filtersUrl[key][option])
          }
        } else {
          for (let option in filtersUrl[key]) {
            newFilterState[key].options[filtersUrl[key][option]] = true
          }
          if (newFilterState[key].options[defaultFilter] === true)
            newFilterState[key].options[defaultFilter] = false
        }
      }
    }

    return newFilterState
  }

  useEffect(() => {
    document.title = 'Teacher - Search'
    if (isLessonPlandEditorOrCollectionEditor()) {
      dispatch(clearSearchEditors())
      // Cleaning URL after using search modal
      let currentUrl = window.location.href
      let baseUrl = currentUrl.split('?')[0]
      window.history.pushState({}, '', baseUrl)
    }

    if (filtersParam) {
      const newFilterObject = updateFiltersFromUrl(filtersParam)
      if (!isLessonPlandEditorOrCollectionEditor()) {
        dispatch(toggleFilters(newFilterObject))
      } else {
        dispatch(toggleSearchEditorsFilters(newFilterObject))
      }

      setFilterObject(newFilterObject)
      setFiltersParam(null)
    }
    if (query) {
      const queryArray = createObjects(query)
      setQuery(null)

      setTagsAndKeywords(queryArray)
    }

    if (sortParam) {
      setSortParam(null)
    }
  }, [])

  // All this code seem no to be useful anymore as URL are irrelevant after the first render. Comment out just in case.
  // useEffect(() => {
  //   if (filtersParam) {
  //     const newFilterObject = updateFiltersFromUrl(filtersParam)

  //     if (!isLessonPlandEditorOrCollectionEditor()) {
  //       dispatch(toggleFilters(newFilterObject))
  //     } else {
  //       dispatch(toggleSearchEditorsFilters(newFilterObject))
  //     }

  //     setFilterObject(newFilterObject)
  //   }
  //   if (query) {
  //     const queryArray = createObjects(query)

  //     setTagsAndKeywords(queryArray)
  //   }
  // }, [location])

  const handleSortBy = item => {
    setSortBy(item)
    if (!isLessonPlandEditorOrCollectionEditor()) {
      dispatch(toggleSort(item))
    } else {
      dispatch(toggleSearchEditorsSort(item))
    }
    addNewUrl(filterObject, tagsAndKeywords, item)
  }
  const getShowAllLabel = type => {
    switch (type) {
      case ContentTypes.TYPE_ARTICLE:
        return ContentTypes.TYPE_ARTICLE

      case ContentTypes.TYPE_LESSON_PLAN_TEMPLATE:
        return ContentTypes.TYPE_LESSON_PLAN_TEMPLATE

      case ContentTypes.TYPE_VIDEO:
        return ContentTypes.TYPE_VIDEO

      case ContentTypes.TYPE_360_VIDEO:
        return ContentTypes.TYPE_360_VIDEO

      default:
        return ContentTypes.TYPE_STORY_WORLD
    }
  }

  const getNameById = item => {
    if (!tagGroups || !subjects) return ''
    if (item.type === 'tags') {
      const tagGroup = tagGroups.find(group =>
        group.tags.some(tag => tag.id === item.value),
      )

      if (tagGroup) {
        const tagItem = tagGroup.tags.find(tag => tag.id === item.value)
        return getTranslatedField(tagItem, 'name')
      }
    }
    if (item.type === 'subjects') {
      const subjectItem = subjects.find(subject => subject.id === item.value)
      return getTranslatedField(subjectItem, 'name')
    }

    return ''
  }

  // ShowAll action
  const handleShowAll = type => {
    const newFilterObject = JSON.parse(
      JSON.stringify(ContentTypes.initialStateFilter),
    )
    Object.keys(newFilterObject.types.options).forEach(key => {
      newFilterObject.types.options[key] = false
    })

    newFilterObject.types.options[type] = true

    if (!isLessonPlandEditorOrCollectionEditor()) {
      dispatch(toggleFilters(newFilterObject))
    } else {
      dispatch(toggleSearchEditorsFilters(newFilterObject))
    }
    setFilterObject(newFilterObject)
  }

  const newType = useMemo(() => {
    let newFilterObject = filterObject

    if (filtersParam) newFilterObject = updateFiltersFromUrl(filtersParam)

    const itemsFiltered = getFilteredItems(newFilterObject, 'types')

    const types = itemsFiltered.map(item => item.value)

    return types
  }, [location, filterObject.types])

  const filterDT = useMemo(() => {
    let newFilterObject = filterObject
    if (filtersParam) newFilterObject = updateFiltersFromUrl(filtersParam)

    const itemsFiltered = getFilteredItems(newFilterObject)

    let suppliedFilter = {
      locale,
      types: newType,
    }

    if (lyftaContent && !isCollectionEditor)
      suppliedFilter.dashboard = 'lesson_plan_builder_search'
    if (isCollectionEditor)
      suppliedFilter.dashboard = 'collection_builder_search'

    if (
      !filtersParam &&
      !query &&
      tagsAndKeywords.length === 0 &&
      itemsFiltered.length === 0 &&
      !lyftaContent &&
      !isCollectionEditor
    ) {
      suppliedFilter.dashboard = 'search_dashboard'
      addNewUrl()

      return suppliedFilter
    }

    // Filtering by keywords
    const keywords = filter(tagsAndKeywords, t => t.type === 'query')

    if (keywords.length > 0) {
      suppliedFilter[`keywords_${getCurrentLanguage()}`] = map(
        keywords,
        k => k.value,
      ).join(',')
    }

    if (query) suppliedFilter[`keywords_${getCurrentLanguage()}`] = query

    const handleOptionsMapping = (options, mapObject) => {
      return Object.keys(options)
        .filter(key => options[key] && key in mapObject)
        .map(key => mapObject[key])
    }

    // Filtering by tags
    if (newFilterObject?.tags?.options)
      suppliedFilter.tags = newFilterObject?.tags?.options

    // Filtering by subjects
    if (newFilterObject?.subjects?.options)
      suppliedFilter.subjects = newFilterObject?.subjects?.options

    // Filtering by ageRanges
    if (newFilterObject?.ageRanges?.options) {
      suppliedFilter.ageRanges = handleOptionsMapping(
        newFilterObject.ageRanges.options,
        ContentTypes.AGE_ID_MAP,
      )
    }

    // Filtering by lessonPlanType
    if (newFilterObject?.lessonPlanTypes?.options) {
      suppliedFilter.lessonPlanTypes = handleOptionsMapping(
        newFilterObject.lessonPlanTypes.options,
        ContentTypes.LESSON_PLAN_TYPE_MAP,
      )
    }

    // Filtering by film durations
    if (newFilterObject?.filmDurations?.options) {
      suppliedFilter.filmDurations = handleOptionsMapping(
        newFilterObject.filmDurations.options,
        ContentTypes.DURATION_ID_MAP,
      )
    }

    // Filtering by bbcf rating
    if (newFilterObject?.bbfcRatings?.options) {
      suppliedFilter.bbfcRatings = handleOptionsMapping(
        newFilterObject.bbfcRatings.options,
        ContentTypes.BBFC_RATING_MAP,
      )
    }
    if (!isLessonPlandEditorOrCollectionEditor()) {
      dispatch(toggleNewSearchTagsAndKeywords(tagsAndKeywords))
    } else {
      dispatch(toggleSearchEditorsKeywords(tagsAndKeywords))
    }
    addNewUrl(filterObject, keywords, sortState)

    suppliedFilter = {
      ...suppliedFilter,
    }

    return suppliedFilter
  }, [tagsAndKeywords, filterObject, locale, query])

  const filterString = useMemo(() => {
    if (isEmpty(tagGroups) || isEmpty(subjects)) return ''

    const filteredApplied = getFilteredItems(filterObject)
    const newArr = []
    if (filteredApplied)
      map(filteredApplied, f => {
        if (['tags', 'subjects'].includes(f?.type)) {
          newArr.push({
            value: getNameById(f).toLowerCase(),
          })
          return
        }

        if (f?.type === 'types') {
          newArr.push({
            value: ContentTypes.contentTypes[f.value].toLowerCase(),
          })
          return
        }

        if (
          [
            'filmDurations',
            'lessonPlanTypes',
            'ageRanges',
            'bbfcRatings',
          ].includes(f?.type)
        ) {
          newArr.push({
            value: getTranslation(`filters.${f.value}`),
          })
          return
        }

        if (f?.value)
          if (f?.type === 'tags') {
            newArr.push({
              type: 'filter',
              value: getNameById(f.value).toLowerCase(),
            })
          } else if (f?.value)
            newArr.push({
              type: 'filter',
              value: f?.value.toLowerCase(),
            })
      })
    if (tagsAndKeywords) {
      map(tagsAndKeywords, f => {
        if (f.value)
          newArr.push({
            value: `"${f.value.toLowerCase()}"`,
          })
      })
    }

    const fs = convertArrayToString({ arr: newArr })

    return fs
  }, [filterObject, newSearch, tagsAndKeywords, tagGroups, subjects])

  const handleIntercomModal = ok => {
    if (ok) {
      const itemName = getTranslatedField(itemSelected, 'name')
      const userMessageToIntercom =
        getTranslation('user.userMessageToIntercom') + itemName

      IntercomAPI('showNewMessage', userMessageToIntercom)
    }
    setShowUnlockModal(false)
  }

  const handleOpenPreview = item => {
    const isLicensed = get(item, 'isLicensed')
    if (isLicensed) {
      if (item.type === 'lesson_plan_template') {
        history.push(
          paths.searchNew.lessonPlanPreview(
            item.lessonPlanTemplate.id,
            item.type,
          ),
        )
      } else {
        history.push(paths.searchNew.resourcePreview(item.id, item.type))
      }
    } else {
      setItemSelected(item)
      setShowUnlockModal(true)
    }
  }

  const order = [
    ContentTypes.TYPE_COLLECTION,
    ContentTypes.TYPE_STORY_WORLD,
    ContentTypes.TYPE_SERIES,
    ContentTypes.TYPE_VIDEO,
    ContentTypes.TYPE_ARTICLE,
    ContentTypes.TYPE_360_VIDEO,
    ContentTypes.TYPE_LESSON_PLAN_TEMPLATE,
  ]

  return (
    <>
      <DataTable
        action={loadContents}
        customHeader={getTranslation('actions.search')}
        defaultPageSize={50}
        filter={filterDT}
        sortProperty={sortBy}
        defaultSort={sortBy}
        removePagination={!filterString && !lyftaContent}
        loadingSelector={getIsLoading}
        pageSelector={getPaged}
        searchComponent={pager => {
          return (
            <Search
              data-intercom-target="Search"
              id="search-content"
              searchLabel="actions.search"
              setTags={setTagsAndKeywords}
              filterString={filterString}
              setFilter={setFilterObject}
              setSortBy={handleSortBy}
              optionChoosen={sortBy}
              filterObject={filterObject}
              pager={pager}
              tags={tagsAndKeywords}
              lyftaContent={lyftaContent}
              isCollectionEditor={isCollectionEditor}
              onSearchChange={setTagsAndKeywords}
              isTrialing={isTrialing(licenseType)}
            />
          )
        }}
        selector={getContents}
      >
        {data => {
          const dataByTypes = groupBy(data, 'type', order)
          return (
            <Flex
              flexDirection="column"
              id="div-card-container"
              overflow="hidden"
              // maxHeight="65vh"
            >
              {dataByTypes.length !== 0 && (
                <SearchResultsFlex>
                  <Flex>
                    {!isEmpty(dataByTypes) && !filterString && !lyftaContent && (
                      <Container id="ribbon-container">
                        {map(dataByTypes, (types, key) => {
                          const sortedTypes = [...dataByTypes[key]].sort(
                            (a, b) => b.id - a.id,
                          )
                          return (
                            <RibbonRow
                              description={
                                loading
                                  ? ''
                                  : getTranslation(`content.description.${key}`)
                              }
                              descriptionElement={DescriptionRibbonRow}
                              showAllTitle={getShowAllLabel(types[key])}
                              showAllAction={() => handleShowAll(key)}
                              titleTag={TitleRibbonRow}
                              showAllTag={ShowAllRibbonRow}
                              title={getTranslation(`content.types.${key}`)}
                              isLoading={loading}
                            >
                              {!loading &&
                                map(sortedTypes, content => {
                                  return (
                                    <Card
                                      controls
                                      height={430}
                                      id={content.id}
                                      item={content}
                                      key={content.id}
                                      width={300}
                                      onView={() => handleOpenPreview(content)}
                                      section="search"
                                      lyftaContent={lyftaContent}
                                    />
                                  )
                                })}
                              {loading && loadingCards}
                            </RibbonRow>
                          )
                        })}
                      </Container>
                    )}
                    {!isEmpty(dataByTypes) && (filterString || lyftaContent) && (
                      <Flex width="100%" height="100%">
                        <SearchResultsGrid>
                          {loading && loadingCards}
                          {!loading &&
                            data &&
                            !lyftaContent &&
                            map(data, (content, index) => (
                              <Card
                                controls
                                favorited={!isEmpty(content.favorites)}
                                height="430px"
                                id={content.id}
                                index={index}
                                item={content}
                                key={content.id}
                                width="100%"
                                onView={() => handleOpenPreview(content)}
                                section="search"
                                lyftaContent={lyftaContent}
                              />
                            ))}
                          {!loading &&
                            data &&
                            lyftaContent &&
                            map(data, (content, index) => {
                              if (!content.isLicensed) return <></>

                              return (
                                <Card
                                  controls
                                  favorited={!isEmpty(content.favorites)}
                                  height="430px"
                                  id={content.id}
                                  index={index}
                                  item={content}
                                  key={content.id}
                                  selectable
                                  width="100%"
                                  onSelect={onSelect}
                                  section="search"
                                  lyftaContent={lyftaContent}
                                />
                              )
                            })}
                        </SearchResultsGrid>
                      </Flex>
                    )}
                  </Flex>
                </SearchResultsFlex>
              )}

              {data.length === 0 && (
                <Container alignItems="center" overflow="auto">
                  <Empty
                    glyphStyle={{ height: '72px', width: '72px' }}
                    mt={4}
                    maxWidth="70%"
                  >
                    <Flex mt={2}>
                      <I18n i18nKey="search.noResultFound" />
                    </Flex>
                    <ContainerUL>
                      <li>
                        {' '}
                        <Trans
                          i18nKey="search.noResultFoundBulletPoint1"
                          components={{ strong: <strong /> }}
                        />
                      </li>
                      <li>
                        {' '}
                        <Trans i18nKey="search.noResultFoundBulletPoint2" />
                      </li>
                      <li>
                        <Trans
                          i18nKey="search.noResultFoundBulletPoint3"
                          components={{ strong: <strong /> }}
                        />
                      </li>
                      <li>
                        <Trans
                          i18nKey="search.noResultFoundBulletPoint4"
                          components={{ strong: <strong /> }}
                        />
                      </li>
                    </ContainerUL>

                    <TransLinkContainer mt={2}>
                      <TransLink
                        components={{
                          Link: (
                            <HelpLink
                              href={`https://help.lyfta.com/${getCurrentLanguage()}/articles/4318429-how-to-find-lyfta-content-you-d-like-to-teach`}
                              target="_blank"
                            />
                          ),
                        }}
                        i18nKey="search.noResultFoundFooter"
                      />{' '}
                    </TransLinkContainer>
                  </Empty>
                </Container>
              )}
            </Flex>
          )
        }}
      </DataTable>
      {showUnlockModal && (
        <UnlockModal
          isOpen={showUnlockModal}
          onCallback={handleIntercomModal}
        />
      )}
    </>
  )
}

SearchPage.defaultProps = {
  lyftaContent: false,
  isCollectionEditor: false,
  onSelect: null,
}

SearchPage.propTypes = {
  lyftaContent: PropTypes.bool,
  isCollectionEditor: PropTypes.bool,
  onSelect: PropTypes.func,
}

export default SearchPage
