import { styled, themeGet } from '@lyfta/components-theme'

import { Flex } from '../../Flex'

export const ShareBox = styled(Flex).attrs({ px: 3, py: 2, mt: 4 })`
  border-radius: 5px;
  border: 1px solid ${themeGet('colors.black_800')};
  align-items: center;
  justify-content: space-between;
`
