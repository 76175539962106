import { getViewerType } from '@lyfta/components-data/src/Store/Selectors/viewer'
import { USER_TYPES } from '@lyfta/components-ui/src/Constants/users'
import { find, findIndex } from 'lodash'
import { all, delay, put, select, take, takeLatest } from 'redux-saga/effects'

import {
  LOAD_EXPERIENCES,
  SAVE_EXPERIENCE,
  requestSave,
} from '../Actions/experiences'
import {
  CLOSE_TASK_COMPLETE,
  REQUEST_ADVANCE_ACTIVITY,
  REQUEST_OPEN_TASK,
  SAVE_RESPONSE,
  SELECT_ACTIVITY_COMPLETE,
  SELECT_TASK,
  questTrackerData,
} from '../Actions/questTracker'
import {
  DRAWER_SHOW_COMPLETE,
  QUEST_HELP_FOUND,
  drawer,
  quest,
} from '../Actions/ui'
import { getExperiences } from '../Selectors/experiences'
import { getCurrentLesson } from '../Selectors/lessons'
import {
  getActivities,
  getCurrentActivity,
  getCurrentTask,
  getIsTeachingInFrontOfClass,
  getTasks,
} from '../Selectors/questTracker'
import { getIsOpen } from '../Selectors/UI/drawer'

function* canMoveToActivity(id) {
  const viewerType = yield select(getViewerType)
  const isTeachingInFrontOfClass = yield select(getIsTeachingInFrontOfClass)

  if (viewerType === USER_TYPES.students || isTeachingInFrontOfClass) {
    const activities = yield select(getActivities)
    let activityId = id

    if (activityId === undefined) {
      const currentActivityIndex = findIndex(activities, a => a.selected)

      if (currentActivityIndex < activities.length) {
        activityId = activities[currentActivityIndex + 1]?.id
      }
    }
    const activityIndex = findIndex(activities, a => a.id === activityId)

    if (activityIndex <= 1) return true

    const prevActivityIndex = activityIndex - 1
    const prevActivity = activities[prevActivityIndex]
    let previousActivityComplete = prevActivity?.complete

    if (prevActivity.tasks.length === 0) {
      // complete the activity as it will be the first one, and nothing else will do it
      previousActivityComplete = true
      yield put(questTrackerData.completeActivity(prevActivity.id))
    }

    return previousActivityComplete
  }
  return true
}

function* canMoveToTask(id) {
  const viewerType = yield select(getViewerType)
  const isTeachingInFrontOfClass = yield select(getIsTeachingInFrontOfClass)

  if (viewerType === USER_TYPES.students || isTeachingInFrontOfClass) {
    const activities = yield select(getActivities)
    const activity = find(
      activities,
      a => findIndex(a.tasks, tId => tId === id) >= 0,
    )
    const taskIndex = findIndex(activity?.tasks, tId => tId === id)
    const prevTaskId = activity?.tasks?.[taskIndex - 1]

    if (!prevTaskId) return true
    // fixes apparent race condition issue where prevTaskComplete would return false when prev task is actually complete
    // (also feels better with slight delay before next tab opens)
    yield delay(300)

    const allTasks = yield select(getTasks)
    const prevTask = find(allTasks, t => t.id === prevTaskId)

    return (prevTask?.complete && prevTask.callToAction !== 'EXPLORE') || false
  }
  return true
}

function* afterSelectTask() {
  yield put(quest.helpMeFind('-1'))
  yield put(drawer.hide())
}

function* advanceActivity({ id, isInteraction }) {
  if (yield canMoveToActivity(id)) {
    const drawerOpen = yield select(getIsOpen)
    const { shown } = yield select(getCurrentActivity)

    if (!shown) {
      yield put(questTrackerData.closeActivity())
    }

    if (!drawerOpen) {
      yield put(questTrackerData.closeTaskNavigation())

      yield put(drawer.show())
      yield take(DRAWER_SHOW_COMPLETE)
    }

    if (!id) {
      yield put(questTrackerData.advanceActivity())
    } else if (isInteraction) {
      yield put(questTrackerData.selectActivity(id))
    } else {
      yield put(questTrackerData.animateActivity(id))
    }
  }

  yield take(SELECT_ACTIVITY_COMPLETE)
  yield put(drawer.hide())
}

function* advanceTask({ id, open }) {
  const { id: taskId, open: currentlyOpen } = yield select(getCurrentTask)
  if (currentlyOpen) {
    yield put(questTrackerData.closeTask(taskId))
    yield take(CLOSE_TASK_COMPLETE)
  }
  if (yield canMoveToTask(id)) {
    yield put(questTrackerData.selectTask(id, open))
  } else {
    yield put(questTrackerData.selectTask(id, false))
  }
}

function* updateExperienceData() {
  const allExperiences = yield select(getExperiences)

  yield put(questTrackerData.updateTaskExperiences(allExperiences))
}

function* closeAndNext() {
  const { tasks, id: activityId } = yield select(getCurrentActivity)
  const { id: taskId, open: currentlyOpen } = yield select(getCurrentTask)

  const currentIndex = tasks.indexOf(taskId)
  const nextIndex = (currentIndex + 1) % tasks.length

  if (currentlyOpen) {
    yield put(questTrackerData.closeTask(taskId))
    yield take(CLOSE_TASK_COMPLETE)
  }

  if (nextIndex === 0) {
    const activities = yield select(getActivities)
    const nextActivityIndex =
      findIndex(activities, a => a.id === activityId) + 1
    yield put(questTrackerData.completeActivity(activityId))

    if (nextActivityIndex < activities.length) {
      yield put(
        questTrackerData.requestAdvanceActivity(
          activities[nextActivityIndex]?.id,
        ),
      )
    } else {
      const currentLesson = yield select(getCurrentLesson)
      if (currentLesson) {
        yield put(
          requestSave({
            type: 'custom',
            verb: 'finished',
            data: {
              time: new Date().toISOString(),
            },
          }),
        )
      }

      yield put(questTrackerData.showAchievement(true))
    }
  } else {
    const nextTask = tasks[nextIndex]
    yield put(questTrackerData.requestOpenTask(nextTask, true))
  }
}

function* handleHelpMeFound({ contentId }) {
  const viewerType = yield select(getViewerType)
  const isTeachingInFrontOfClass = yield select(getIsTeachingInFrontOfClass)

  if (viewerType === USER_TYPES.students || isTeachingInFrontOfClass) {
    yield put(requestSave({ type: 'helpMeFound', contentId }))
  }
  yield closeAndNext()
}

function* saveResponse({ taskId, data }) {
  const viewerType = yield select(getViewerType)
  const isTeachingInFrontOfClass = yield select(getIsTeachingInFrontOfClass)

  if (viewerType === USER_TYPES.students || isTeachingInFrontOfClass) {
    yield put(requestSave({ type: 'answer', taskId, data }))
  }

  yield closeAndNext()
}

export default function* root() {
  yield all([
    takeLatest(SAVE_RESPONSE, saveResponse),
    takeLatest(SELECT_TASK, afterSelectTask),
    takeLatest(REQUEST_ADVANCE_ACTIVITY, advanceActivity),
    takeLatest(REQUEST_OPEN_TASK, advanceTask),
    takeLatest(LOAD_EXPERIENCES.SUCCESS, updateExperienceData),
    takeLatest(SAVE_EXPERIENCE.SUCCESS, updateExperienceData),
    takeLatest(QUEST_HELP_FOUND, handleHelpMeFound),
  ])
}
