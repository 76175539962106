import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { questTrackerData } from '../../../../../Store/Actions/questTracker'

const useSelectActivity = id => {
  const dispatch = useDispatch()
  const selectActivity = useCallback(() => {
    dispatch(questTrackerData.requestAdvanceActivity(id, true))
  }, [id])

  return selectActivity
}

export default useSelectActivity
