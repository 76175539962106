import { getTranslation } from '@lyfta/components-i18n'
import colors from '@lyfta/components-theme/src/themes/Main/colors'
import {
  ButtonNew,
  Icon,
  Notification as NotificationIcon,
  NotificationPlus,
  USER_TYPES,
} from '@lyfta/components-ui'
import { Heart, HeartOutline } from '@lyfta/components-ui/src/Assets/Sidebar'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { Container } from './styles'

const Favorite = ({
  button,
  disableContainerStyling,
  favorite,
  favoriteProperty,
  contentId,
  height,
  width,
  viewerType,
  onAddToFavorites,
  onTryRemoveFromFavorites,
  showDeleteConfirmation,
  unfavoredColor,
  favoriteColor,
  heartOutline,
  heartOutlineColor,
  defaultStyle,
  isCollection,
}) => {
  const getFavorite = isEmpty(favoriteProperty) ? favorite : favoriteProperty[0]
  const actionType = getFavorite ? 'remove' : 'add'
  const onClickFavorite = useCallback(
    e => {
      e.stopPropagation()
      if (getFavorite) {
        return new Promise(resolve =>
          onTryRemoveFromFavorites(
            { favorite: getFavorite, showDeleteConfirmation, contentId },
            resolve,
            {
              success: {
                message: !isCollection
                  ? getTranslation('toast.success.favoriteRemove')
                  : getTranslation('toast.success.unsubscribed'),
              },
            },
          ),
        ).then(() => {})
      }

      return onAddToFavorites(contentId, {
        success: {
          message: !isCollection
            ? getTranslation('toast.success.favoriteAdd')
            : getTranslation('toast.success.subscribed'),
        },
      })
    },
    [getFavorite],
  )

  return (
    <>
      {viewerType === USER_TYPES.teachers && button && !isCollection && (
        <ButtonNew
          data-intercom-target="favorite-btn"
          default={{
            borderColor: 'transparent',
            fill: () =>
              getFavorite ? colors[favoriteColor] : colors[unfavoredColor],
            path: {
              stroke: () =>
                getFavorite
                  ? colors[favoriteColor]
                  : colors[heartOutlineColor] || colors[unfavoredColor],
            },
            ...defaultStyle,
          }}
          focusVisible={{
            backgroundColor: colors.neutral_100,
            fill: () => (getFavorite ? colors[favoriteColor] : 'transparent'),
            outlineColor: colors[unfavoredColor],
            path: { stroke: colors[favoriteColor] },
          }}
          hover={{
            backgroundColor: colors.neutral_100,
            borderColor: 'transparent',
            fill: () => (getFavorite ? colors[favoriteColor] : 'transparent'),
            path: { stroke: colors[favoriteColor] },
          }}
          leadingIcon={
            <Container
              disableContainerStyling={disableContainerStyling}
              onClick={onClickFavorite}
            >
              <Icon
                glyph={HeartOutline}
                height={height}
                id={`heart-icon-${contentId}-${
                  getFavorite ? 'checked' : 'noChecked'
                }`}
                width={width}
              />
            </Container>
          }
          onClick={onClickFavorite}
          pressed={{
            backgroundColor: 'unset',
            borderColor: 'transparent',
            fill: colors[favoriteColor],
            path: { stroke: 'transparent' },
          }}
          secondary
          title={getTranslation(`actions.${actionType}Favorite`)}
          type="icon"
        />
      )}
      {viewerType === USER_TYPES.teachers &&
        !button &&
        !isCollection &&
        heartOutline && (
          <Container
            disableContainerStyling={disableContainerStyling}
            onClick={onClickFavorite}
          >
            <Icon
              data-intercom-target="heart-icon"
              fill={getFavorite ? favoriteColor : unfavoredColor}
              fillHover={getFavorite ? favoriteColor : 'clear'}
              glyph={HeartOutline}
              height={height}
              id={`heart-icon-${contentId}-${
                getFavorite ? 'checked' : 'noChecked'
              }`}
              stroke={
                getFavorite
                  ? favoriteColor
                  : heartOutlineColor || unfavoredColor
              }
              strokeHover={favoriteColor}
              width={width}
              onClick={onClickFavorite}
            />
          </Container>
        )}
      {viewerType === USER_TYPES.teachers &&
        !button &&
        !isCollection &&
        !heartOutline && (
          <Container
            disableContainerStyling={disableContainerStyling}
            onClick={onClickFavorite}
          >
            <Icon
              data-intercom-target="heart-icon"
              fill={getFavorite ? favoriteColor : unfavoredColor}
              fillHover={getFavorite ? unfavoredColor : favoriteColor}
              glyph={Heart}
              height={height}
              id={`heart-icon-${contentId}-${
                getFavorite ? 'checked' : 'noChecked'
              }`}
              width={width}
            />
          </Container>
        )}
      {viewerType === USER_TYPES.teachers && isCollection && (
        <Container
          disableContainerStyling={disableContainerStyling}
          onClick={onClickFavorite}
        >
          {/* SVG issue with NotificationPlus. The size doesn't match with its counterpart. That's why sizes in Icon are different */}
          <Icon
            fill="secondary_100"
            glyph={getFavorite ? NotificationIcon : NotificationPlus}
            height={!getFavorite ? '25px' : '20px'}
            width={!getFavorite ? '25px' : '20px'}
            id={`subscribe-icon-${contentId}-${
              getFavorite ? 'checked' : 'noChecked'
            }`}
          />
        </Container>
      )}
    </>
  )
}

Favorite.defaultProps = {
  button: false,
  contentId: null,
  disableContainerStyling: false,
  favorite: null,
  favoriteProperty: null,
  favoriteColor: 'scarletRed',
  heartOutline: false,
  isCollection: false,
  heartOutlineColor: 'gray',
  height: 18,
  showDeleteConfirmation: false,
  unfavoredColor: 'gray',
  width: 20,
  defaultStyle: {},
}

Favorite.propTypes = {
  button: PropTypes.bool,
  contentId: PropTypes.string,
  disableContainerStyling: PropTypes.bool,
  favorite: PropTypes.object,
  favoriteProperty: PropTypes.object,
  favoriteColor: PropTypes.string,
  heartOutline: PropTypes.bool,
  isCollection: PropTypes.bool,
  heartOutlineColor: PropTypes.string,
  height: PropTypes.number,
  showDeleteConfirmation: PropTypes.bool,
  unfavoredColor: PropTypes.string,
  viewerType: PropTypes.string.isRequired,
  width: PropTypes.number,
  onAddToFavorites: PropTypes.func.isRequired,
  onTryRemoveFromFavorites: PropTypes.func.isRequired,
  defaultStyle: PropTypes.object,
}

export default Favorite
