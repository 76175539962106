import { styled, themeGet } from '@lyfta/components-theme'

import { Search } from '../../Assets/Sidebar'
import { Flex } from '../Flex'

export const Container = styled(Flex)`
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${themeGet('colors.black_600')};
  font-size: 19px;
  line-height: 28px;
  path {
    fill: ${themeGet('colors.black_300')};
  }
`
export const SearchIcon = styled(Search).attrs(() => ({}))`
  fill: ${themeGet('colors.black_300')};
  margin-bottom: 20px;
`
