import { Api as apiCall, createAsyncAction } from '@lyfta/components-data'

export const LOAD_COLLECTIONS = createAsyncAction(
  'collections/LOAD_COLLECTIONS',
)
export const LOAD_COLLECTION = createAsyncAction('collections/LOAD_COLLECTION')
export const CREATE_COLLECTION = createAsyncAction(
  'collections/CREATE_COLLECTION',
)
export const DELETE_COLLECTION = createAsyncAction(
  'collections/DELETE_COLLECTION',
)

export const UPDATE_COLLECTION = createAsyncAction(
  'collections/UPDATE_COLLECTION',
)
export const REQUEST_UPDATE_COLLECTION = 'collection/REQUEST_UPDATE_COLLECTION'
export const REQUEST_SAVE_COLLECTION = 'collection/REQUEST_SAVE_COLLECTION'
export const CHANGE_SIZE = 'collections/CHANGE_SIZE'
export const CHANGE_NUMBER = 'collections/CHANGE_NUMBER'
export const ADD_COLLECTION_CONTENT = createAsyncAction(
  'collecion/ADD_COLLECTION_CONTENT',
)
export const REMOVE_COLLECTION_CONTENT = createAsyncAction(
  'classes/REMOVE_COLLECTION_CONTENT',
)
export const clearCurrentCollection = () => ({ type: LOAD_COLLECTION.CLEAR })

export const loadCollections = ({ filter, page } = {}) =>
  apiCall({
    method: 'GET',
    endpoint: '/collections',
    query: {
      include: 'content,contents,favorites',
      page,
      filter,
    },
    paged: true,
    types: LOAD_COLLECTIONS,
  })

export const loadCollection = id =>
  apiCall({
    method: 'GET',
    endpoint: `/collections/${id}`,
    query: {
      include:
        'content,contents,contents.lessonPlanTemplate,favorites,content.tags,content.ageRanges,content.subjects',
    },
    types: LOAD_COLLECTION,
  })

export const createCollection = attributes => {
  return apiCall({
    method: 'POST',
    endpoint: '/collections',
    query: {
      data: {
        type: 'collections',
        attributes,
      },
    },
    types: CREATE_COLLECTION,
  })
}

export const deleteCollection = id =>
  apiCall({
    method: 'DELETE',
    endpoint: `/collections/${id}`,
    query: {},
    types: DELETE_COLLECTION,
  })

export const requestSaveCollection = (payload, resolve) => ({
  type: REQUEST_SAVE_COLLECTION,
  payload,
  resolve,
})
// TODO: to pass locale properly
export const requestUpdateCollection = data => {
  return {
    type: REQUEST_UPDATE_COLLECTION,
    data,
    locale: 'En',
  }
}
export const updateCollection = ({ attributes, customMessage }) => {
  return apiCall({
    method: 'PATCH',
    customMessage,
    endpoint: `/collections/${attributes.id}`,
    query: {
      data: {
        type: 'collections',
        attributes,
      },
    },
    types: UPDATE_COLLECTION,
  })
}
export const addContentToCollection = ({
  collectionContent,
  customMessage,
}) => {
  const collection = collectionContent[0]
  return apiCall({
    method: 'POST',
    customMessage,
    endpoint: `/collections/${collection.id}/relationships/contents`,
    query: {
      data: [
        {
          type: collection.type,
          // type: 'contents',
          id: collection.contentId,
        },
      ],
    },
    updateRelationships: [
      {
        modelName: 'collections',
        id: collection.id,
        relationship: 'contents',
      },
    ],
    types: ADD_COLLECTION_CONTENT,
  })
}

export const removeContentFromCollection = ({
  collection,
  content,
  customMessage,
}) => {
  return apiCall({
    method: 'DELETE',
    customMessage,
    endpoint: `/collections/${collection.id}/relationships/contents`,
    query: {
      data: [
        {
          type: 'contents',
          id: content.id,
        },
      ],
    },
    updateRelationships: [
      {
        modelName: 'collections',
        id: collection.id,
        relationship: 'contents',
      },
    ],
    types: REMOVE_COLLECTION_CONTENT,
  })
}
