import { uniqBy } from 'lodash'
import { useEffect, useState } from 'react'

export const useInputItems = items => {
  const [inputItems, setInputItems] = useState([])
  useEffect(() => {
    setInputItems(uniqBy(items, 'id') || [])
  }, [items])

  const filterItems = ({ inputValue }) => {
    const regex = new RegExp(inputValue, 'i')
    setInputItems(
      items.filter(item => item.name.match(regex) && item.state === 'active') ||
        [],
    )
  }

  return [inputItems, filterItems]
}
