import { isTeacherApp } from '@lyfta/components-config'
import {
  TYPE_COLLECTION,
  TYPE_LESSON_PLAN_TEMPLATE,
  TYPE_SERIES,
  TYPE_STORY_WORLD,
} from '@lyfta/components-content/src/Constants/contents'
import { getIcon } from '@lyfta/components-content/src/Utils'
import { getLocale } from '@lyfta/components-data/src/Store/Selectors/persist'
import {
  I18n,
  TranslatedField,
  formatDate,
  getTranslatedField,
  getTranslation,
} from '@lyfta/components-i18n'
import { themeGet } from '@lyfta/components-theme'
import {
  Bin,
  ButtonNew,
  ContentWarning,
  Flex,
  Graph,
  Icon,
  Location as Loc,
  Lock,
  Series,
  Storyworld,
  capitalizeFirstLetter,
  convertArrayToString,
  reactEvent,
} from '@lyfta/components-ui'
import get from 'lodash/get'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import Player from 'react-player'
import { useSelector } from 'react-redux'

import Favorite from '../Favorite'
import {
  AuthorContainer,
  BodyContainer,
  Container,
  ContentInfo,
  Controls,
  DeleteButton,
  DescriptionContainer,
  DurationContainer,
  FooterContainer,
  HeaderContainer,
  KeywordContainer,
  LocationContainer,
  PublishedContainer,
  StyledButton,
  ThumbHolder,
  Title,
  TitleContainer,
  TypeLabel,
  TypeLabelContainer,
  TypeRow,
  ViewContainer,
} from './styles'

const Card = ({
  item,
  onSelect,
  onView,
  selectable,
  height,
  width,
  showDeleteFavouriteConfirmation,
  isLoading,
  showTrailer,
  favoriteProperty,
  section,
  lyftaContent,
  deleteIconAction,
}) => {
  const handlePreview = () => {
    reactEvent({
      action: 'clicked_content_card',
      params: {
        content_type: get(item, 'type') || false,
        content_id: get(item, 'id'),
        content_name: getTranslatedField(item, 'name'),
        content_section: section,
      },
    })

    onView(item)
  }

  const handleSelect = () => {
    onSelect(item)
  }

  const locale = useSelector(getLocale)
  const localeCapitalized = capitalizeFirstLetter(locale)
  const contentId = get(item, 'id')
  const isLicensed = get(item, 'isLicensed')
  const type = get(item, 'type') || false
  const parentContent = get(item, 'parent') || false
  const parentName = get(item, 'parentName') || false
  const createdByLyfta = get(item, 'createdByLyfta') || false
  const trailerUrl = get(item, 'trailerUrl') || false
  const posterUrl = get(item, 'posterUrl') || false

  const keywords = convertArrayToString({
    arr: get(item, `keywords${localeCapitalized}`),
    conjunction: false,
    maxItems: 3,
  })

  let keywordsString = getTranslation('collections.keywords')

  if (keywords) {
    keywordsString = `${keywordsString} ${keywords}`
  }

  const favorite = get(item, 'favorites') || favoriteProperty || false
  const contentWarningDescription = get(
    item,
    'contentWarningDescription',
    false,
  )

  const bbfcRatingIcon = get(item, 'bbfcRatingIconUrl', false)
  const bbfcRatingDescription = get(
    item,
    `bbfcRatingDescription${localeCapitalized}`,
    false,
  )
  const bbfcRatingName = get(item, 'bbfcRatingName', false)
  const bbfcRatingShortDescription =
    bbfcRatingDescription && (bbfcRatingName === 'U' || bbfcRatingName === 'PG')
      ? `${bbfcRatingName} - ${bbfcRatingDescription.split('-')[0]}`
      : bbfcRatingName

  const viewedCount = get(item, 'viewedCount') || false
  const filmDuration = get(item, 'filmDuration') || false
  const filmDurationRemainingSeconds = `${
    filmDuration % 60 > 9 ? filmDuration % 60 : `0${filmDuration % 60}`
  }`
  const filmDurationMinutesSeconds = `${Math.floor(
    filmDuration / 60,
  )}:${filmDurationRemainingSeconds}`
  const bbfcNoContentWarning =
    !!bbfcRatingDescription && !contentWarningDescription
  const publishedTime = get(item, 'createdAt') || ''

  if (isLoading)
    return (
      <Container width={width} height={height}>
        <Skeleton
          width={300}
          height={167}
          style={{ lineHeight: 24, borderRadiusTop: '8px' }}
        />
        <Skeleton
          width={150}
          height={30}
          style={{ marginLeft: '16px', borderRadiusTop: '16px' }}
        />
        <Skeleton
          width={250}
          height={22}
          marginTop={10}
          style={{ marginLeft: '16px', marginTop: '10px' }}
        />
        <Skeleton
          width={250}
          height={22}
          style={{ marginLeft: '16px', marginTop: '10px' }}
        />
        <Flex flexDirection="row">
          <Skeleton
            width={24}
            height={22}
            style={{
              marginLeft: '16px',
              marginTop: '10px',
              borderRadius: '50%',
            }}
          />

          <Skeleton
            width={150}
            height={22}
            style={{ marginLeft: '16px', marginTop: '10px' }}
          />
        </Flex>
      </Container>
    )

  return (
    <Container
      data-intercom-target={contentId}
      height={height}
      width={width}
      id={`card-${contentId}`}
      onClick={selectable ? handleSelect : handlePreview}
    >
      <HeaderContainer position="relative">
        {deleteIconAction && !isTeacherApp && (
          <DeleteButton>
            <ButtonNew
              leadingIcon={<Icon glyph={Bin} height={18} width={18} />}
              type="icon"
              style={{
                maxHeight: '30px',
                maxWidth: '30px',
                minHeight: '30px',
                minWidth: '30px',
                borderWidth: 0,
                paddingLeft: '8px',
                paddingRight: '8px',
              }}
              onClick={event => {
                event.stopPropagation()
                deleteIconAction(item)
              }}
              delete
              secondary
            />
          </DeleteButton>
        )}

        {trailerUrl && showTrailer ? (
          <Player
            controls
            width="100%"
            height="100%"
            playing={false}
            url={trailerUrl}
          />
        ) : (
          <ThumbHolder type={type} image={posterUrl}>
            {filmDuration && (
              <DurationContainer>
                {filmDurationMinutesSeconds}
              </DurationContainer>
            )}
            {viewedCount && (
              <ViewContainer>
                <Icon
                  glyph={Graph}
                  fill={themeGet('colors.black_300')}
                  height={12}
                  mr={2}
                  width={12}
                />{' '}
                {viewedCount}
                <Flex ml={1}>
                  {getTranslation(`actions.views`).toLocaleLowerCase()}
                </Flex>
              </ViewContainer>
            )}
          </ThumbHolder>
        )}
      </HeaderContainer>

      <BodyContainer>
        <TypeRow>
          <TypeLabelContainer bbfcNoContentWarning={bbfcNoContentWarning}>
            <Icon
              glyph={getIcon(type)}
              width={16}
              height={16}
              fill={themeGet('colors.black_600')}
            />

            <TypeLabel>
              {' '}
              {getTranslation(`core.contentTypes.${type}`)}
            </TypeLabel>
          </TypeLabelContainer>
          {contentWarningDescription && (
            <Icon
              title={contentWarningDescription}
              glyph={ContentWarning}
              height="24px"
              width="24px"
              ml={1}
            />
          )}
          {bbfcRatingIcon && (
            <Icon
              title={bbfcRatingShortDescription}
              glyph={
                <img
                  alt={bbfcRatingShortDescription}
                  src={bbfcRatingIcon}
                  height="24px"
                  width="26px"
                />
              }
              ml={1}
            />
          )}
        </TypeRow>

        <TitleContainer>
          <Title>
            <TranslatedField
              object={item}
              field="name"
              id={`card-title-${contentId}`}
            />
          </Title>
        </TitleContainer>

        <Flex flexDirection="column" width="100%">
          <TranslatedField
            tag={DescriptionContainer}
            object={item}
            field={
              type === TYPE_COLLECTION &&
              !getTranslatedField(item, 'shortDescription')
                ? 'longDescription'
                : 'shortDescription'
            }
            ellipsis
          />
          {type === TYPE_STORY_WORLD ||
            (type === TYPE_SERIES && (
              <LocationContainer>
                <Icon glyph={Loc} width={16} height={16} fill="gray" />
                <Flex ml={2}>
                  <TranslatedField object={item} field="country" />
                </Flex>
              </LocationContainer>
            ))}

          {type === TYPE_STORY_WORLD &&
            (parentContent.seriesName || parentName) && (
              <LocationContainer>
                <Icon
                  title={getTranslation('core.contentTypes.series')}
                  glyph={Series}
                  width={16}
                  height={16}
                  fill="gray"
                />
                <Flex ml={2}>{parentContent.seriesName || parentName}</Flex>
              </LocationContainer>
            )}
          {type !== TYPE_STORY_WORLD &&
            type !== TYPE_LESSON_PLAN_TEMPLATE &&
            type !== TYPE_SERIES &&
            (parentContent || parentName) && (
              <LocationContainer>
                <Icon
                  title={getTranslation('core.contentTypes.story_world')}
                  glyph={Storyworld}
                  width={16}
                  height={16}
                  fill="gray"
                />
                <Flex ml={2}>{parentContent.storyWorldName || parentName}</Flex>
              </LocationContainer>
            )}
          {type === TYPE_COLLECTION && keywords && (
            <KeywordContainer>{keywordsString}</KeywordContainer>
          )}
        </Flex>
        <ContentInfo>
          {type === TYPE_COLLECTION && createdByLyfta && (
            <I18n tag={AuthorContainer} i18nKey="collections.createdByLyfta" />
          )}
          {publishedTime && (
            <PublishedContainer>
              {getTranslation('content.labels.published')}{' '}
              {formatDate(publishedTime, { year: 'numeric' })}
            </PublishedContainer>
          )}
        </ContentInfo>
      </BodyContainer>

      <FooterContainer>
        {isLicensed && (
          <Controls>
            {isTeacherApp && (
              <Flex
                width="50%"
                id={`div-add-remove-favorite-content-id-${contentId}`}
                justifyContent="center"
              >
                <Favorite
                  heartOutline
                  favoriteProperty={favorite}
                  contentId={contentId}
                  showDeleteConfirmation={showDeleteFavouriteConfirmation}
                  isCollection={type === TYPE_COLLECTION}
                />
              </Flex>
            )}
            <StyledButton
              id={`btn-view-content-id-${contentId}`}
              inverse
              noborder
              type="link"
              isTeacherApp={isTeacherApp}
            >
              <I18n i18nKey={lyftaContent ? 'actions.add' : 'actions.view'} />
            </StyledButton>
          </Controls>
        )}

        {!isLicensed && (
          <Controls>
            <Flex width="100%" justifyContent="center">
              <Icon
                fill={themeGet('colors.black_600')}
                glyph={Lock}
                width={18}
                height={20}
              />
            </Flex>
          </Controls>
        )}
      </FooterContainer>
    </Container>
  )
}

Card.defaultProps = {
  item: null,
  favoriteProperty: null,
  selectable: false,
  onSelect: noop,
  onView: noop,
  height: '410px',
  width: '300px',
  section: '',
  showDeleteFavouriteConfirmation: false,
  isLoading: false,
  showTrailer: false,
  lyftaContent: false,
  deleteIconAction: false,
}

Card.propTypes = {
  favoriteProperty: PropTypes.object,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLoading: PropTypes.bool,
  item: PropTypes.shape({
    type: PropTypes.string,
    posterUrlSmall: PropTypes.string,
  }),
  lyftaContent: PropTypes.bool,
  section: PropTypes.string,
  selectable: PropTypes.bool,
  deleteIconAction: PropTypes.bool,
  showDeleteFavouriteConfirmation: PropTypes.bool,
  showTrailer: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSelect: PropTypes.func,
  onView: PropTypes.func,
}

export default Card
