import * as React from 'react'

function SvgTeach(props) {
  return (
    <svg
      fill="none"
      height="14"
      viewBox="0 0 18 14"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.0316 4.65733L8.93537 0.60071C8.66718 0.466335 8.34321 0.466436 8.07512 0.600979L0.969291 4.16709C0.344971 4.48041 0.344436 5.30521 0.968349 5.61922L8.50517 9.41237L15.3063 5.96195V10.0161C15.3063 10.473 15.7088 10.8434 16.2053 10.8434H16.6018C17.0983 10.8434 17.5007 10.473 17.5007 10.0161V5.38389C17.5007 5.08093 17.3208 4.8022 17.0316 4.65733Z"
        fill="black"
      />
      <path
        d="M2.91021 10.3489V8.48629L8.50517 11.2814L14.1147 8.48629V10.3483C14.1147 10.6531 13.9325 10.9333 13.6406 11.0773L8.93057 13.4018C8.66491 13.5329 8.34552 13.5327 8.08002 13.4014L3.38346 11.0775C3.09201 10.9333 2.91021 10.6534 2.91021 10.3489Z"
        fill="black"
      />
    </svg>
  )
}

export default SvgTeach
