import { I18n, getTranslation } from '@lyfta/components-i18n'
import { themeGet } from '@lyfta/components-ui'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { MascotDefault, MascotHover } from '../../../../Assets/Menu'
import { drawer } from '../../../../Store/Actions/ui'
import {
  getActivities,
  getTasks,
} from '../../../../Store/Selectors/questTracker'
import { getIsOpen } from '../../../../Store/Selectors/UI/drawer'
import { getIsRichMediaOpen } from '../../../../Store/Selectors/UI/quest'
import { CloseButton } from '../../Buttons'
import {
  Container,
  LeftColumnContainer,
  MascotIcon,
  Percent,
  PercentText,
  ProgressBar,
  ProgressFill,
  QuestTitle,
  RightColumnContainer,
  TitleText,
  UpperContainer,
} from './styles'

const TopPanel = ({ delayMascotChange, durationMascotChange, questTitle }) => {
  const [isHoverMascot, setIsHoverMascot] = useState(false)

  const tasks = useSelector(getTasks)
  const activities = useSelector(getActivities)
  const isDrawerOpen = useSelector(getIsOpen)
  const isRichMediaOpen = useSelector(getIsRichMediaOpen)
  const previousCompletedActivitiesFraction = useRef(null)
  const completeActivities = useMemo(() => {
    return activities.filter(activity => {
      return activity.complete
    })
  }, [activities])
  const completeTasks = useMemo(() => {
    return tasks.filter(task => {
      return task.complete
    })
  }, [tasks])
  const completedActivitiesFraction = useMemo(() => {
    return completeActivities.length / activities.length
  }, [activities, completeActivities])

  const completed = completeTasks.length / tasks.length
  const pct = Number.isNaN(completed) ? 0 : Math.round(completed * 100)
  const pctTxt = `${pct}%`

  const dispatch = useDispatch()
  const closePanel = useCallback(() => {
    dispatch(drawer.hide())
  })

  useEffect(() => {
    let timer
    if (
      isDrawerOpen &&
      completedActivitiesFraction !==
        previousCompletedActivitiesFraction.current &&
      !isRichMediaOpen
    ) {
      previousCompletedActivitiesFraction.current = completedActivitiesFraction
      timer = setTimeout(() => {
        setIsHoverMascot(true)
        setTimeout(() => {
          setIsHoverMascot(false)
        }, durationMascotChange)
      }, delayMascotChange)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [completedActivitiesFraction, isDrawerOpen, isRichMediaOpen])

  return (
    <Container>
      <UpperContainer>
        <LeftColumnContainer>
          <TitleText>
            <QuestTitle>
              {questTitle && questTitle !== '' ? (
                questTitle
              ) : (
                <I18n text="quest.title" />
              )}
            </QuestTitle>
          </TitleText>

          <PercentText>
            <Percent id="span-percent-completion">{pctTxt}</Percent>{' '}
            <I18n text="quest.complete" />
          </PercentText>
        </LeftColumnContainer>
        <RightColumnContainer>
          <CloseButton
            defaultFill={themeGet('colors.neutral_100')}
            id="btn-close-activities-list"
            text={getTranslation('quest.close')}
            onClick={closePanel}
          />
          <MascotIcon
            glyph={isHoverMascot ? MascotHover : MascotDefault}
            height={56}
            width={56}
            mr={6}
          />
        </RightColumnContainer>
      </UpperContainer>
      <ProgressBar>
        <ProgressFill pct={pct} />
      </ProgressBar>
    </Container>
  )
}

export default TopPanel

TopPanel.defaultProps = {
  questTitle: '',
}

TopPanel.propTypes = {
  delayMascotChange: PropTypes.number.isRequired,
  durationMascotChange: PropTypes.number.isRequired,
  questTitle: PropTypes.string,
}
